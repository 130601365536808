/* eslint-disable import/no-cycle */
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import { createSelector } from 'reselect';

import { generateInitialSelectors } from '@docavenue/core';

import {
  isDocumentSharedWithAgendaSettings,
  isSentByPatient,
} from '../../components/utils/tools';
import { Dictionary, Document } from '../../types/pro.types';
import patientsSelectors from './patientsSelectors';

import { RootState } from '@/src/reducer/rootState';
import agendaSettingsDefaultSelectors from '@/src/selector/agendaSettingsDefaultSelectors';
import {
  GenericSelector,
  InitialGenericSelector,
} from '@/src/selector/selectorsType';

const initialSelectors: InitialGenericSelector<Document> = generateInitialSelectors(
  'documents',
);

const createDocumentsGroup = (
  documents: Array<Document>,
  filter: 'PATIENT' | 'PRO' | null | undefined,
) => {
  const orderedDocuments = orderBy(
    documents.filter(document =>
      filter
        ? (document.sharedInformation?.sourceUserType as any)?.name === filter
        : true,
    ),
    [element => moment(element.creationDate).format('YYYY-MM-DD')],
    ['desc'],
  );

  return groupBy(orderedDocuments, element =>
    moment(element.creationDate).format('YYYY-MM-DD'),
  );
};

const getUnseenDocuments = createSelector(
  [
    initialSelectors.getItems,
    agendaSettingsDefaultSelectors.getItems,
    patientsSelectors.getItem,
  ],
  (documents, agendaSettings, patient) =>
    patient === null
      ? []
      : documents.filter(
          doc =>
            doc.isSeen === false &&
            isSentByPatient(doc) &&
            isDocumentSharedWithAgendaSettings(doc, agendaSettings),
        ),
);

const getDocumentsGroupedByDate = createSelector(
  initialSelectors.getItems,
  (_, filter) => filter,
  (documents, filter: 'PATIENT' | 'PRO' | null) =>
    createDocumentsGroup(documents, filter),
);

export type DocumentSelector = GenericSelector<Document> & {
  getUnseenDocuments: (state: RootState) => Document[];
  getDocumentsGroupedByDate: (
    state: RootState,
    filter: 'PATIENT' | 'PRO' | null,
  ) => Dictionary<Document[]>;
};

const selectors: DocumentSelector = {
  name: 'documents',
  ...initialSelectors,
  getUnseenDocuments,
  getDocumentsGroupedByDate,
};

export default selectors;
