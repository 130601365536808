import {
  ANNOUNCEMENT,
  HEALTH_CHECK_ACCOUNT_CREATION_PRO,
  HEALTH_CHECK_FRONT_PRO,
  HEALTH_CHECK_IDENTITY_VERIFICATION_PRO,
} from '@docavenue/core';

const MAINTENANCE = 'MAINTENANCE'.toLowerCase();
const ENCRYPTION = 'ENCRYPTION'.toLowerCase();
const ADDRESS_SERVER_URL = 'addressServerUrl';

export type AnnouncementState = {
  maintenance: Record<string, boolean>;
  encryption: boolean;
  status: Record<string, string>;
};

export const initialState: AnnouncementState = {
  maintenance: {
    [HEALTH_CHECK_FRONT_PRO]: true,
    [HEALTH_CHECK_ACCOUNT_CREATION_PRO]: true,
    [HEALTH_CHECK_IDENTITY_VERIFICATION_PRO]: true,
  },
  encryption: false,
  status: {
    addressServerUrl: 'initial',
  },
};

const announcementReducer = (services: string[]) => (
  state = initialState,
  action: Record<string, any>,
) => {
  if (action?.type !== ANNOUNCEMENT) {
    return state;
  }
  const {
    payload: { service: announcementService, ...body },
  } = action;

  if (!services.includes(announcementService)) {
    return state;
  }
  switch (body?.channel) {
    case MAINTENANCE:
      return {
        ...state,
        maintenance: {
          ...state.maintenance,
          [announcementService]: body?.status,
        },
      };
    case ENCRYPTION:
      return {
        ...state,
        [body.channel]: body.status,
      };
    case ADDRESS_SERVER_URL:
      return {
        ...state,
        [body.channel]: body.url,
        status: {
          ...state.status,
          [body.channel]: 'fetched',
        },
      };
    default:
      return state;
  }
};

export default announcementReducer;
