import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import range from 'lodash/range';
import { createSelector } from 'reselect';
import { makeStyles } from 'tss-react/mui';
import { useDebouncedCallback } from 'use-debounce';

import { serialActions } from '@docavenue/core';
import {
  Center,
  Patient,
  Practitioner,
} from '@maiia/model/generated/model/api-pro/api-pro';

import { useTranslation } from '../../../i18n';
import {
  complexFormActions,
  patientsActions,
  Search,
  searchActions,
} from '../../../src/actions';
import Routes from '../../../src/constants/routes';
import { useOpenComplexForm } from '../../../src/hooks/actions';
import {
  useCenterId,
  useIsFreemium,
  useIsRegulator,
} from '../../../src/hooks/selectors';
import { useAnalyticsTracker } from '../../../src/hooks/utils';
import ClickAwayListener from '../../atoms/ClickAwayListener';
import FontAwesomeIcon from '../../atoms/FontawesomeIcon/FontawesomeIcon';
import {
  formatAutoCompleteInput,
  patientInputToObject,
} from '../../utils/tools';
import Autocomplete, { AUTOCOMPLETE_DIVIDER } from '../Autocomplete';
import { DIVIDER } from '../Autocomplete/Autocomplete';
import HeaderSearchInput from './HeaderSearchInput';
import HeaderSearchItem from './HeaderSearchItem';
import HeaderSearchListComponent from './HeaderSearchListComponent';

import {
  GAActions,
  GAActionStatus,
  GACategories,
  GALabels,
  GALabelsType,
} from '@/src/constants/tracking';
import { useSelector } from '@/src/hooks/redux';

const useStyles = makeStyles()({
  autCompleteListRoot: {
    width: '100%',
  },
});

export type PractitionerWithCenters = Practitioner & {
  centers?: Center[];
};

type SearchInput = Center | Patient | PractitionerWithCenters;

type SearchOutput = SearchInput & {
  centerId?: string;
  center?: Center;
};

const getPractitionerWithCenter = (users: SearchInput[]): SearchOutput[] => {
  const items = [] as SearchOutput[];

  for (const user of users) {
    if (user.objectType === 'practitioner') {
      const centerId: string = get(user, 'agendaSettings.centerId');
      const center = (user as PractitionerWithCenters)?.centers?.find(
        (c: Center) => c.id === centerId,
      );
      items.push({
        ...user,
        centerId,
        center,
      });
    } else items.push(user);
  }
  return items;
};

const getSearchSlotsFromUsers = users => {
  const MAX_PRAT_HITS = 4;
  const MAX_CENTER_HITS = 2;
  const usersWeights = {
    patient: 1,
    agendaSettings: 2,
    center: 3,
  };

  let centerHits = 0;
  let pratHits = 0;

  const slots = range(12).map(() => {
    if (centerHits < MAX_CENTER_HITS) {
      const centerIdx = users.findIndex(u => u.objectType === 'center');
      if (centerIdx !== -1) {
        const center = users.splice(centerIdx, 1)[0];
        if (center) {
          center.key = center.id;
          centerHits++;
        }
        return center;
      }
    }
    if (pratHits < MAX_PRAT_HITS) {
      const pratIdx = users.findIndex(u => u.objectType === 'agendaSettings');
      if (pratIdx !== -1) {
        const prat = users.splice(pratIdx, 1)[0];
        if (prat && prat.center) {
          prat.key = `${prat.id}_${prat.center.id}`;
          pratHits++;
          return prat;
        }
      }
    }
    const patientIdx = users.findIndex(u => u.objectType === 'patient');
    if (patientIdx !== -1) {
      const patient = users.splice(patientIdx, 1)[0];
      patient.key = patient.id;
      return patient;
    }
    return null;
  });

  return orderBy(
    slots.filter(slot => !!slot),
    [item => usersWeights[item.objectType]],
    ['asc'],
  );
};

type SearchResult = Search | DIVIDER<any>;

export const addDividersToResults = (
  results: SearchInput[],
): SearchResult[] => {
  const resultsWithDividers: SearchResult[] = [...results];

  const firstPractIndex = resultsWithDividers.findIndex(
    result => (result as SearchInput).objectType === 'agendaSettings',
  );
  if (firstPractIndex > 0) {
    resultsWithDividers.splice(firstPractIndex, 0, AUTOCOMPLETE_DIVIDER);
  }

  const firstCenterIndex = resultsWithDividers.findIndex(
    result => (result as SearchInput).objectType === 'center',
  );
  if (firstCenterIndex > 0 && firstCenterIndex !== firstPractIndex) {
    resultsWithDividers.splice(firstCenterIndex, 0, AUTOCOMPLETE_DIVIDER);
  }

  return resultsWithDividers;
};

const getSearchItems = createSelector(
  store => store.search.items as SearchInput[],
  results =>
    addDividersToResults(
      getSearchSlotsFromUsers(getPractitionerWithCenter(results)),
    ),
);

const HeaderSearchAutocomplete = () => {
  const searchRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation('common');
  const items = useSelector(getSearchItems);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const { classes } = useStyles();
  const patientCenterId = useCenterId();
  const [isOpen, setOpen] = useState(false);
  const router = useRouter();
  const trackUserEvent = useAnalyticsTracker(GACategories.Header);
  const openComplexForm = useOpenComplexForm();
  const centerId = useCenterId();
  const isFreemium = useIsFreemium();
  const isRegulatorUser = useIsRegulator();

  // @ts-ignore
  const redirectToComplexAppointment = (
    center,
    practitioner,
    patient,
    gaLabel: GALabelsType,
  ) => {
    const currentEvent = {
      centerId: center?.id,
      center,
      practitionerId: practitioner?.id,
      practitioner,
      patient,
    };
    const openComplexFormEvent = patient
      ? GAActions.OpenComplexFormResearchedPatient
      : GAActions.OpenComplexFormResearchedPractitioner;

    const _serialActions = serialActions.serial([
      () =>
        patient?.id
          ? patientsActions.getOne(patient?.id, {
              params: { centerId },
            })
          : patientsActions.setItem(null),
      () => complexFormActions.setItem(currentEvent),
      () => openComplexForm(),
    ]);

    _serialActions.onSuccess = () => {
      trackUserEvent(GAActions.SelectSearch, GAActionStatus.SUCCESS, gaLabel);
      trackUserEvent(openComplexFormEvent, GAActionStatus.SUCCESS);
    };
    _serialActions.onError = () => {
      trackUserEvent(GAActions.SelectSearch, GAActionStatus.ERROR, gaLabel);
      trackUserEvent(openComplexFormEvent, GAActionStatus.ERROR);
    };

    dispatch(_serialActions);
  };

  const blurSearchInput = () => {
    searchRef.current?.blur();
    setOpen(false);
  };

  const redirectToAgenda = (
    centerId_: string,
    practitionerId: string,
    gaLabel: GALabelsType,
  ) => {
    trackUserEvent(
      GAActions.ClickSearchActionIcon,
      GAActionStatus.SUCCESS,
      gaLabel,
    );
    blurSearchInput();
    dispatch(patientsActions.setItem(null));
    router.push({
      pathname: Routes.ROOT,
      query: {
        centerId: centerId_,
        practitionerId,
      },
    });
  };

  const redirectToEditPatient = (patientId: string, gaLabel: GALabelsType) => {
    trackUserEvent(
      GAActions.ClickSearchActionIcon,
      GAActionStatus.SUCCESS,
      gaLabel,
    );
    blurSearchInput();
    router.push({
      pathname: Routes.EDIT_PATIENT,
      query: {
        ...router.query,
        patientId,
      },
    });
  };

  const onCreateNewPatient = () => {
    trackUserEvent(GAActions.CreatePatient, GAActionStatus.SUCCESS);
    dispatch(patientsActions.setItem(patientInputToObject(searchTerm)));
    setSearchTerm('');
    router.push({
      pathname: Routes.EDIT_PATIENT,
      query: pick(router.query, ['centerId', 'practitionerId']),
    });
  };

  const searchForTerm = useDebouncedCallback((term: string) => {
    dispatch(
      searchActions.getList({
        term: formatAutoCompleteInput(term),
        limit: 12,
        patientCenterId,
      }),
    );
  }, 300);

  return (
    <ClickAwayListener onClickAway={blurSearchInput}>
      <Autocomplete
        isMenuOpen={isOpen}
        items={items}
        inputRef={searchRef}
        ItemComponent={HeaderSearchItem}
        itemProps={{
          searchTerms: searchTerm
            ? [searchTerm].concat(searchTerm.split(' '))
            : '',
          redirectToEditPatient,
          redirectToAgenda,
        }}
        InputComponent={HeaderSearchInput}
        inputProps={{
          placeholder: t('headersearchautocomplete_placeholder'),
          setOpen,
        }}
        endAdornment={
          <FontAwesomeIcon
            name="magnifying-glass:regular"
            className="absolute left-2 h-3 w-3"
          />
        }
        listClassName={classes.autCompleteListRoot}
        ListComponent={HeaderSearchListComponent}
        listProps={{
          blurSearchInput,
          onCreateNewPatient,
          className: 'top-[30px]',
        }}
        optionKey="key"
        // @ts-ignore
        onChange={(selected: Practitioner | Patient | Center | null) => {
          blurSearchInput();
          switch (selected?.objectType) {
            case 'patient':
              if (isFreemium || isRegulatorUser) {
                redirectToEditPatient(
                  get(selected, 'id'),
                  GALabels.GoPatientFile,
                );
              } else {
                redirectToComplexAppointment(
                  get(selected, 'center'),
                  get(selected, 'practitioner'),
                  selected,
                  GALabels.PatientAppointment,
                );
              }
              break;
            case 'agendaSettings':
              redirectToComplexAppointment(
                get(selected, 'center'),
                get(selected, 'practitioner'),
                null,
                GALabels.PractitionerAppointment,
              );
              break;
            case 'center':
              redirectToComplexAppointment(
                selected,
                null,
                null,
                GALabels.CenterAppointment,
              );
              break;
            default:
              break;
          }
        }}
        formatInputForComparaison={() => ''}
        inputValue={searchTerm}
        setInputValue={(term = '') => {
          setSearchTerm(term);
          if (!term) {
            searchForTerm.cancel();
            dispatch(searchActions.setItems([]));
          } else {
            searchForTerm(term);
          }
        }}
      />
    </ClickAwayListener>
  );
};

export default HeaderSearchAutocomplete;
