import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useTranslation } from '../../../i18n';
import {
  consultationReasonsActions,
  weekTemplateCyclesActions,
} from '../../../src/actions';
import Routes from '../../../src/constants/routes';
import { useCenterId, usePractitionerId } from '../../../src/hooks/selectors';
import { isIntegrationTestEnv } from '../../../src/utils/EnvUtils';
import Image from '../../atoms/Image/Image';
import Typography from '../../atoms/Typography/Typography';
import DialogActions from '../CardLayout/DialogActions';
import DialogContent from '../CardLayout/DialogContent';
import Dialog from '../Dialog/Dialog';

import { useSelector } from '@/src/hooks/redux';

const useStyles = makeStyles()((theme: any) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(4),
  },
  illustration: {
    width: 350,
  },
  typo: {
    maxWidth: 650,
  },
  linkButton: {
    textDecoration: 'none',
    '& button': {
      ...theme.typography.body1,
      padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
    },
  },
}));

type Props = {
  onClose?: () => void;
};

function AutoOnboardingWelcomeDialog({ onClose }: Props) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const centerId = useCenterId();
  const practitionerId = usePractitionerId();

  const hasWeekTemplateCycles = useSelector(
    state => !!state.weekTemplateCycles.items.length,
  );
  const hasConsultationReasons = useSelector(
    state => !!state.consultationReasons.items.length, // TODO: We will need to filter special consultation reason added to callibri users
  );
  const [isOpen, setIsOpen] = useState(
    !hasWeekTemplateCycles && !hasConsultationReasons,
  );

  useEffect(() => {
    if (!hasWeekTemplateCycles) {
      dispatch(weekTemplateCyclesActions.getList({ centerId, practitionerId }));
    }
    if (!hasConsultationReasons) {
      dispatch(
        consultationReasonsActions.getList({ centerId, practitionerId }),
      );
    }
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    onClose?.();
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={t('common:auto-onboarding-welcome-title')}
      onClose={handleClose}
    >
      <DialogContent className={classes.content}>
        <Image
          className={classes.illustration}
          src="/static/images/auto-onboarding-welcome.svg"
        />
        <Typography className={classes.typo}>
          {t('common:auto-onboarding-welcome-content')}
        </Typography>

        <a
          href={t('common:zendesk_configure_agenda')}
          target="_blank"
          className={classes.linkButton}
          rel="noreferrer"
        >
          <Button variant="contained" color="primary" onClick={handleClose}>
            {t('common:auto-onboarding-welcome-button')}
          </Button>
        </a>
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
}

export const useDisplayAutoOnboardingWelcomeDialog = () => {
  const { pathname } = useRouter();
  const authorizedPaths: string[] = [Routes.ROOT];
  const [alreadyDisplayed, setAlreadyDisplayed] = useState(false);
  const agendaSettings = useSelector(state => state.agendaSettings.item);
  const hasBeenConfigured = agendaSettings?.hasBeenConfigured;

  const handleCloseDialog = () => {
    setAlreadyDisplayed(true);
  };

  return {
    canDisplayDialog:
      !isIntegrationTestEnv &&
      !!agendaSettings &&
      !hasBeenConfigured &&
      !alreadyDisplayed &&
      authorizedPaths.includes(pathname),
    handleCloseDialog,
  };
};

export default AutoOnboardingWelcomeDialog;
