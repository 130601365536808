/* eslint-disable import/no-cycle */
import { useCallback, useContext } from 'react';
import { Dayjs } from 'dayjs';
import find from 'lodash/find';
import moment, { Moment } from 'moment';
import { createSelector } from 'reselect';

import {
  CenterDTO,
  Practitioner,
} from '@maiia/model/generated/model/api-pro/api-pro';

import { CalendarContext } from '../../components/contexts';
import { AgendaInterface } from '../../components/contexts/AgendaContext';
import { useTranslation } from '../../i18n';
import { AGENDA, DAY, WEEK } from '../actions/calendar';
import { daysOfTheWeek, FORMAT_HH_MM_SS } from '../constants';
import {
  getAgendaRange,
  getDisplayTimesByAgendas,
  getUnitCountForAge,
} from '../utils';
import { useDate, useDateByCalendar } from './date';
import { useCalendarView } from './query';
import {
  useGetAgendasWeekDaysSettings,
  useGetTimeSlotResourcesWeekDaysSettings,
} from './selectors';

import { useSelector } from '@/src/hooks/redux';

export const useGetAgendasDisplayTimes = () => {
  const agendaSettings = useSelector(state => state.agendaSettings.items);

  const getDisplayTimes = useCallback(
    agendas => {
      const displayTimesByAgendas = getDisplayTimesByAgendas(
        agendaSettings,
        agendas,
      );

      return displayTimesByAgendas.length
        ? displayTimesByAgendas.reduce((previousTimes, newTimes) => {
            if (previousTimes) {
              const [prevStart, prevEnd] = previousTimes;
              const [start, end] = newTimes;
              const newStart = moment(prevStart, FORMAT_HH_MM_SS).isBefore(
                moment(start, FORMAT_HH_MM_SS),
              )
                ? prevStart
                : start;
              const newEnd = moment(prevEnd, FORMAT_HH_MM_SS).isAfter(
                moment(end, FORMAT_HH_MM_SS),
              )
                ? prevEnd
                : end;
              return [newStart, newEnd];
            }
            return newTimes;
          })
        : ['08:00:00', '20:00:00'];
    },
    [agendaSettings],
  );
  return getDisplayTimes;
};

export const useDisplayDateRange = (
  agendas: AgendaInterface[],
  nbDisplayedDays: number,
  displayedTimeSlotResourcesCount?: number,
) => {
  const timeSlotResourcesMode = !!displayedTimeSlotResourcesCount;
  const date = moment(
    useDateByCalendar(
      timeSlotResourcesMode ? displayedTimeSlotResourcesCount : agendas.length,
    ),
  ).toISOString();

  const getAgendasWeekDaysSettings = useGetAgendasWeekDaysSettings();
  const timeSlotResourcesWeekDaysSettings = useGetTimeSlotResourcesWeekDaysSettings();
  const agendasWeekDaysSettings = getAgendasWeekDaysSettings(agendas);

  const weekDaysSettings = timeSlotResourcesMode
    ? timeSlotResourcesWeekDaysSettings
    : agendasWeekDaysSettings;

  const rangeMemo = getAgendaRange(date, weekDaysSettings, nbDisplayedDays);

  return rangeMemo;
};

export const useAgendaDateLabel = () => {
  const { range, nbDisplayedCalendars } = useContext(CalendarContext)!;
  const view = useCalendarView();
  const date = useDate();
  switch (view) {
    case DAY:
    case AGENDA:
      return moment(date).format('dddd DD MMM');
    case WEEK: {
      const startDay =
        nbDisplayedCalendars > 1
          ? moment(range[0])
          : moment(date).startOf('isoWeek');
      const endDay =
        nbDisplayedCalendars > 1
          ? moment(range[range.length - 1])
          : startDay.clone().add(6, 'days');
      return `${startDay.format('DD')}-${endDay.format('DD MMM YYYY')}`;
    }
    default:
      break;
  }
};

export function useDisplayTimes(): [Moment, Moment];
export function useDisplayTimes(type: 'string'): [string, string];
export function useDisplayTimes(type: 'moment'): [Moment, Moment];
export function useDisplayTimes(type: 'date'): [Date, Date];
export function useDisplayTimes(
  type?: 'string' | 'moment' | 'date',
): [string, string] | [Moment, Moment] | [Date, Date] {
  const { displayStartTime, displayEndTime } =
    useContext(CalendarContext) ?? {};
  const startTime = displayStartTime ?? '08:00:00';
  const endTime = displayEndTime ?? '20:00:00';
  if (type === 'string') {
    return [startTime, endTime];
  }
  const start = moment(startTime, FORMAT_HH_MM_SS);
  let end = moment(endTime, FORMAT_HH_MM_SS);
  if (displayEndTime === '00:00:00') {
    end = end.endOf('day');
  }
  if (type === 'date') {
    return [start.toDate(), end.toDate()];
  }
  return [start, end];
}

export const useDisplayDays = (
  practitioner: Practitioner | null,
  center: CenterDTO | null,
) =>
  useSelector(
    createSelector(
      state => state.agendaSettings.items,
      items => {
        if (!practitioner || !center) return daysOfTheWeek;
        const match = find(items, {
          practitionerId: practitioner.id,
          centerId: center.id,
        });
        if (match?.agendaDisplaySettings) {
          return match.agendaDisplaySettings.displayDays.map(day =>
            daysOfTheWeek.find(dayOfTheWeek => dayOfTheWeek.key === day),
          );
        }
        return daysOfTheWeek;
      },
    ),
  );

export const useConvertDateToAge = (
  date?: string | Moment | Dayjs | Date,
): string | undefined => {
  const { t } = useTranslation();
  const patientAgeDetails = getUnitCountForAge(date);
  if (!patientAgeDetails) return undefined;
  const [units, age] = patientAgeDetails;
  return t(units, { count: age });
};
