/* eslint-disable import/no-cycle */
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import find from 'lodash/find';
import { createSelector } from 'reselect';

import { ParsedUrlQuery } from 'querystring';

import {
  asyncActions,
  errorActions,
  serialActions,
  websocketActions,
} from '@docavenue/core';
import {
  ProfessionalCardDTO,
  ReceiverData,
  ResultPageProfessionalCardDTO,
} from '@maiia/model/generated/model/api-pro/api-pro';

import {
  chatInvitationsActions,
  chatMessagesActions,
  chatRoomDocumentsActions,
  searchInvitationActions,
  unreadMessagesActions,
  usersActions,
} from '../../actions';

import { CHAT_PAGINATION_LIMIT } from '@/components/utils/constants';
import { ChatRoutes } from '@/src/constants/routes';
import { ChatContext, ScrollManagerContext } from '@/src/contexts';
import { useSelector } from '@/src/hooks/redux';
import { useCenterId, useUserId } from '@/src/hooks/selectors';
import { ChatState } from '@/src/reducer';
import { isMobile } from '@/src/utils/chat/chat.utils';
import { ChatSubmitDocument } from '@/types/chat.types';

export const useWsConnectResource = (
  resource: string,
  newRoom?: string | null,
  options: Object = {},
) => {
  const { connected, rooms } = useSelector(state => state.websocket);
  const room: { room: string; resource: string } | undefined = useMemo(
    () => find(rooms, { resource } as any),
    [rooms, resource],
  );
  const dispatch = useDispatch();
  const roomToLeave = useRef<string | null>(null);

  useEffect(() => {
    if (room && room.room !== newRoom) {
      roomToLeave.current = null;
      dispatch(websocketActions.leave(room.room));
    }
    if (connected && newRoom && !find(rooms, { room: newRoom } as any)) {
      roomToLeave.current = newRoom;
      dispatch(websocketActions.join(newRoom, resource, options));
    }
  }, [connected, newRoom, room]);

  useEffect(
    () => () => {
      if (roomToLeave.current) {
        dispatch(websocketActions.leave(roomToLeave.current));
      }
    },
    [],
  );
};

const useWsConnectResourceMultiRooms = (
  newRoomsToJoins: string[],
  resource: string,
  options = {},
) => {
  const dispatch = useDispatch();

  const currentRooms = useRef<any>(null);
  useEffect(() => {
    (currentRooms.current || [])
      .filter((room: string) => !newRoomsToJoins.includes(room))
      .forEach((room: string) => {
        dispatch(websocketActions.leave(room));
      });

    newRoomsToJoins
      .filter(room => !(currentRooms.current || []).includes(room))
      .forEach((room: string, index: number) => {
        dispatch(websocketActions.join(room, `${resource}_${index}`, options));
      });

    currentRooms.current = newRoomsToJoins;
  }, [newRoomsToJoins]);
};

// Only used in this file
export const useAsyncActionCallback = (
  {
    getAction,
    successAction,
    errorAction,
  }: {
    getAction: (...params: any[]) => any;
    successAction: (data: any, options: any) => any;
    errorAction: (
      actions: { action: (...params: any[]) => any; error: any },
      params: { userId: string | null },
    ) => any;
  },
  deps: any[],
) => {
  const userId = useUserId();
  const dispatch = useDispatch();
  return useCallback(async (...params) => {
    try {
      const data = await asyncActions(dispatch, getAction(...params));
      dispatch(successAction(data, { userId }));
      return data;
    } catch (error) {
      dispatch(errorActions.addError(getAction, error as Record<string, any>));
      dispatch(errorAction({ action: getAction, error }, { userId }));
    }
  }, deps);
};

export const useGetUnreadMessages = (pathsToExclude: string[]) => {
  const { useRouter } = useContext(ChatContext);
  const { pathname } = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!pathsToExclude.includes(pathname)) {
      dispatch(unreadMessagesActions.getList());
    }
  }, [pathname]);
};

export const useGetReceivedInvitations = (): any =>
  useAsyncActionCallback(
    {
      getAction: () => chatInvitationsActions.getReceivedInvitations(),
      successAction: chatInvitationsActions.getReceivedInvitationsSuccess,
      errorAction: chatInvitationsActions.addError,
    },
    [],
  );

export const useGetSentInvitations = () =>
  useAsyncActionCallback(
    {
      getAction: () =>
        chatInvitationsActions.getSentInvitations({}, { version: 2 }),
      successAction: chatInvitationsActions.getSentInvitationsSuccess,
      errorAction: chatInvitationsActions.addError,
    },
    [],
  );

export const usePractitionerSearch = () => {
  const dispatch = useDispatch();
  const setSearchTermDebounced = async (
    identifier?: string,
    insee?: string,
    speciality?: string | null,
    page: number = 0,
    limit: number = 20,
  ): Promise<ResultPageProfessionalCardDTO> => {
    // @ts-ignore
    const response = await asyncActions<ResultPage<ProfessionalCardDTO>>(
      dispatch,
      searchInvitationActions.getList({
        identifier: identifier || undefined,
        speciality: speciality || undefined,
        insee: insee || undefined,
        limit,
        page,
      }),
    );
    return { items: response.items, total: response.total };
  };

  return setSearchTermDebounced;
};

export const useReSendInvitation = () => {
  const dispatch = useDispatch();
  const centerId = useCenterId();
  const reSendInvitation = useCallback(
    async (chatInvitationId: string, receiverData: ReceiverData) => {
      dispatch(
        chatInvitationsActions.reSendInvitation(
          chatInvitationId,
          receiverData,
          centerId,
        ),
      );
    },
    [],
  );

  return reSendInvitation;
};

export const useAcknowledgeChatMessages = () => {
  const userId = useUserId();
  return useAsyncActionCallback(
    {
      getAction: (chatRoomId: string) =>
        chatMessagesActions.acknowledge({
          params: { chatRoomId },
        }),
      successAction: chatMessagesActions.acknowledgeSuccess,
      errorAction: chatMessagesActions.addError,
    },
    [userId],
  );
};

export const useWsConnectChatByUser = () => {
  const userId = useUserId();
  const centerId = useCenterId();
  const newRoom = useMemo(() => {
    if (!userId) {
      return null;
    }

    // TODO: optimize backend to use chat_userId_centerId and include other chatrooms (without centerId) until now we can use chet_userId
    return `chat_${userId}`;
    // return isMobile() ? `chat_${userId}` : `chat_${userId}_${centerId}`;
  }, [userId, centerId]);
  useWsConnectResource('chat', newRoom);
};

const centerIdsRoomsSelector = createSelector(
  (state: ChatState) => state.users.item?.userProInformation?.associatedCenters,
  (associatedCenters = []) =>
    associatedCenters.map(center => `chatUsersConnected_${center.centerId}`),
);

const emptyArray: string[] = [];

export const useWsConnectChatByCenters = () => {
  const isTls =
    useSelector(state => state.users.item?.role?.name) === 'TELESECRETARY';
  const isAuthenticatedWs = useSelector(
    state => state?.websocket?.authenticated,
  );

  const centerIdsRooms = useSelector(centerIdsRoomsSelector);
  useWsConnectResourceMultiRooms(
    isTls || !isAuthenticatedWs ? emptyArray : centerIdsRooms,
    'chatUsersConnected',
    {
      isInteractive: true,
      resource: 'chatUsersConnected',
    },
  );
};

export const useMemoizedValues = <T extends {}>(value: T) =>
  useMemo(() => value, Object.values(value));

export const useChangeChatRoom = () => {
  const dispatch = useDispatch();
  const {
    useRouter,
    // #CHANGE_CHAT
    isChatRoomVisible,
  } = useContext(ChatContext);
  const acknowledgeChatMessages = useAcknowledgeChatMessages();
  const router = useRouter();
  const { query: queryRouter } = router;

  const changeChatRoom = useCallback(
    (chatRoomId?: string, centerId?: string | null) => {
      if (!chatRoomId) return;

      dispatch(
        serialActions.serial([
          () =>
            chatMessagesActions.setCurrentChatRoomId({
              chatRoomId,
            }),
          () =>
            chatRoomId && centerId
              ? usersActions.updateOne(
                  {},
                  {
                    chunkResource: `last-visited-chat-room`,
                    params: { chatRoomId, centerId },
                  },
                )
              : serialActions.continue(),
          () => {
            const query: ParsedUrlQuery = {
              ...queryRouter,
              chatRoomId,
            };

            if (centerId) query.centerId = centerId;

            const route = {
              pathname: ChatRoutes.CHAT,
              query,
            };
            router.replace(route, isMobile());
            return serialActions.continue();
          },
          () => chatMessagesActions.setChatRoomIncomingCall(null),
          () =>
            chatMessagesActions.getList({
              chatRoomId,
              limit: CHAT_PAGINATION_LIMIT,
            }),
          () =>
            isChatRoomVisible
              ? acknowledgeChatMessages(chatRoomId)
              : serialActions.continue(),
        ]),
      );
    },
    [queryRouter],
  );

  return changeChatRoom;
};

export const useWsConnectChatUserList = () => {
  const userId = useUserId();
  const newRoom = useMemo(() => {
    if (!userId) {
      return null;
    }
    return `chat_users_${userId}`;
  }, [userId]);
  useWsConnectResource('chat_users', newRoom);
};

export const useTotalUnreadMessagesCount = (centerId?: string | null) => {
  const unreadMessages = useSelector(s => s.unreadMessages.items);
  const invitationsReceived =
    useSelector(state => state.chatInvitations?.receive?.items) || [];
  const result = useMemo(
    () =>
      unreadMessages.reduce(
        (total, message) => {
          const count = { ...total };
          const messagesCount = message.unreadMessageCount || 0;
          const threadsCount = message.unreadThreadCount || 0;

          count.messagesAndThreadsCountFromAllCenters +=
            messagesCount + threadsCount;
          if (
            !centerId ||
            !message.centerId ||
            (message.centerId && centerId === message.centerId)
          ) {
            count.messagesAndThreadsCountFromCenter +=
              messagesCount + threadsCount;
            count.messagesCountFromCenter += messagesCount;
            count.threadsCountFromCenter += threadsCount;
          }

          return count;
        },
        {
          messagesAndThreadsCountFromAllCenters: 0,
          messagesAndThreadsCountFromCenter: 0,
          messagesCountFromCenter: 0,
          threadsCountFromCenter: 0,
          awaitingInvitationsCount: invitationsReceived.length || 0,
        },
      ),
    [unreadMessages, centerId, invitationsReceived],
  );

  return result;
};

interface IntersectionObserverArgs extends IntersectionObserverInit {
  freezeOnceVisible?: boolean;
}

export function useIntersectionObserver(
  node: HTMLDivElement | undefined,
  {
    threshold = 0,
    root = null,
    rootMargin = '0%',
    freezeOnceVisible = false,
  }: IntersectionObserverArgs,
): IntersectionObserverEntry | undefined {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([ent]: IntersectionObserverEntry[]): void => {
    setEntry(ent);
  };

  useEffect(() => {
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) return;

    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => observer.disconnect();
  }, [node, JSON.stringify(threshold), root, rootMargin, frozen]);

  return entry;
}

export const useAddDocumentToChatRoom = () => {
  const documents = useSelector(state => state.chatRoomDocuments.items);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const userId = useUserId()!;
  const centerId = useCenterId()!;
  const { useRouter } = useContext(ChatContext);
  const {
    query: { chatRoomId },
  } = useRouter();
  const { scrollToBottom } = useContext(ScrollManagerContext);
  const addDocument = useCallback<ChatSubmitDocument>(
    (values, onSuccess, onError) => {
      const { file, category, practitioner } = values;

      setIsLoading(true);
      const document = {
        practitionerId: practitioner?.id,
        centerId,
        category,
      };
      const _serialActions = serialActions.serial([
        () =>
          chatRoomDocumentsActions.setItems([
            ...documents,
            {
              userId,
              chatRoomId: chatRoomId as string,
              file,
              document,
              centerId,
            },
          ]),
        // @ts-ignore
        () => setIsLoading(false),
        // @ts-ignore
        () => scrollToBottom?.(),
      ]);

      _serialActions.onSuccess = () => onSuccess?.();
      _serialActions.onError = () => onError?.();

      dispatch(_serialActions);
    },
    [centerId, userId, chatRoomId, scrollToBottom, documents],
  );

  return { addDocument, isLoading, setIsLoading };
};
