import React from 'react';
import dynamic from 'next/dynamic';

import { documentsSelectors } from '../../../src/selector';

import { useSelector } from '@/src/hooks/redux';

const DocumentViewer = dynamic(() => import('./DocumentViewer'), {
  ssr: false,
});

export default function DocumentOverlay() {
  const document = useSelector(documentsSelectors.getItem);

  if (!document) {
    return null;
  }

  return <DocumentViewer document={document} />;
}
