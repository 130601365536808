import { createSelector } from 'reselect';

import { generateInitialSelectors } from '@docavenue/core';
import {
  ChatRoomDTO,
  User,
} from '@maiia/model/generated/model/api-pro/api-pro';

import {
  findUsersChatRoomsOrderedByLatestChatMessage,
  getIsChatRoomGroup,
  getIsInvitationChatRoom,
  getUserInChatroom,
  sortUsersChatRoomsOrderedByLastNameAndFirstName,
} from '../utils/chat/chat.utils';

import { RootState } from '@/src/reducer/rootState';
import { InitialGenericSelector } from '@/src/selector/selectorsType';

const initialSelectors: InitialGenericSelector<ChatRoomDTO> = generateInitialSelectors(
  'chatRooms',
);

/**
 ========== Center chat rooms selectors ==========
 * Selectors looking for chat rooms linked to centers by a center ID
 */
const getCenterChatRooms = createSelector(
  state => state.chatRooms.items,
  chatRooms => chatRooms.filter(chatRoom => !getIsInvitationChatRoom(chatRoom)),
);
const getCenterChatRoomsWithLoading = createSelector(
  getCenterChatRooms,
  state => state.chatRooms.isLoading,
  (chatRooms, isLoading) => ({ chatRooms, isLoading }),
);

const getCenterIndividualChatRooms = createSelector(
  state => state.chatRooms.items,
  chatRooms =>
    chatRooms.filter(
      chatRoom =>
        !getIsInvitationChatRoom(chatRoom) && !getIsChatRoomGroup(chatRoom),
    ),
);

const getCenterGroupChatRooms = createSelector(
  state => state.chatRooms.items,
  chatRooms =>
    chatRooms.filter(
      chatRoom =>
        !getIsInvitationChatRoom(chatRoom) && getIsChatRoomGroup(chatRoom),
    ),
);

/**
 ========== Other chat rooms selectors ==========
 * Selectors looking for chat rooms without center ID
 * Those chat rooms are refered as otherChatRooms.
 * This kind of chatRoom is used to chat with an invited parctitioner with whom the user has no common center.
 */
const getOtherChatRooms = createSelector(
  state => state.chatRooms.items,
  chatRooms => chatRooms.filter(chatRoom => getIsInvitationChatRoom(chatRoom)),
);

const getOtherIndividualChatRooms = createSelector(
  state => state.chatRooms.items,
  chatRooms =>
    chatRooms.filter(
      chatRoom =>
        getIsInvitationChatRoom(chatRoom) && !getIsChatRoomGroup(chatRoom),
    ),
);

const getOtherGroupChatRooms = createSelector(
  state => state.chatRooms.items,
  chatRooms =>
    chatRooms.filter(
      chatRoom =>
        getIsInvitationChatRoom(chatRoom) && getIsChatRoomGroup(chatRoom),
    ),
);

/**
 * Because state.users does not lists "other chatroom users"
 * This selector does the job by collecting users inside every otherChatRooms and returning a list of those users.
 */
const getOtherChatRoomUserList = createSelector(
  [state => state.users.item?.id, getOtherIndividualChatRooms],
  (userId, otherChatRooms) =>
    otherChatRooms
      .map(chatroom => getUserInChatroom(chatroom, userId!))
      .filter((foundUser): foundUser is User => !!foundUser),
);

/**
 ========== Common for center and other chatrooms ==========
 */
const getAllGroupChatRooms = createSelector(
  state => state.chatRooms.items,
  chatRooms => chatRooms.filter(chatRoom => getIsChatRoomGroup(chatRoom)),
);

const getGroupChatRoomsByType = createSelector(
  getCenterGroupChatRooms,
  getOtherGroupChatRooms,
  (_, isOtherChatRoomMode: boolean) => isOtherChatRoomMode,
  (chatRoomsGroup, otherChatRoomsGroup, isOtherChatRoomMode) =>
    isOtherChatRoomMode ? otherChatRoomsGroup : chatRoomsGroup,
);

const getUsersFromChatRoom = createSelector(
  state => state.chatRooms.items,
  (_, chatRoomId: string) => chatRoomId,
  (chatRooms, chatRoomId) =>
    chatRooms.find(chatRoom => chatRoom.id === chatRoomId),
);

const getUsersChatRoomsOrderedByLatestChatMessage = createSelector(
  getCenterIndividualChatRooms,
  getOtherIndividualChatRooms,
  state => state.chatMessages.itemsDictMap,
  state => state.users.item,
  state => state.users.items,
  getOtherChatRoomUserList,
  (
    centerRooms,
    otherRooms,
    itemsDictMap,
    user,
    centerUsers,
    usersFromOtherChatRooms,
  ) =>
    findUsersChatRoomsOrderedByLatestChatMessage(
      centerRooms,
      otherRooms,
      itemsDictMap as any,
      user,
      centerUsers,
      usersFromOtherChatRooms,
    ),
);

const getUsersChatRoomsOrderedByLastNameAndFirstName = createSelector(
  (state: RootState) => state.users.items,
  getOtherChatRoomUserList,
  (centerUsers: User[], usersFromOtherChatRooms: User[]) =>
    sortUsersChatRoomsOrderedByLastNameAndFirstName(
      centerUsers,
      usersFromOtherChatRooms,
    ),
);

const getIsExternalChatRoomById = createSelector(
  [(state, chatRoomId: string) => initialSelectors.getById(state, chatRoomId)],
  (chatRoom?: ChatRoomDTO) => {
    return getIsInvitationChatRoom(chatRoom);
  },
);
const selectors = {
  name: 'chatRooms',
  ...initialSelectors,
  getCenterChatRooms,
  getCenterChatRoomsWithLoading,
  getCenterIndividualChatRooms,
  getCenterGroupChatRooms,
  getOtherChatRooms,
  getOtherIndividualChatRooms,
  getOtherGroupChatRooms,
  getOtherChatRoomUserList,
  getGroupChatRoomsByType,
  getAllGroupChatRooms,
  getUsersFromChatRoom,
  getUsersChatRoomsOrderedByLatestChatMessage,
  getUsersChatRoomsOrderedByLastNameAndFirstName,
  getIsExternalChatRoomById,
};

export type ChatRoomsSelector = typeof selectors;

export default selectors;
