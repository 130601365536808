import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { useTranslation } from '../../../i18n';
import { practitionersSelectors } from '../../../src/selector';
import FontAwesomeIcon from '../../atoms/FontawesomeIcon/FontawesomeIcon';
import Tooltip from '../../atoms/Tooltip';
import { useAnalyticsContext } from '../../contexts';

import { GAActions } from '@/src/constants/tracking';
import { useSelector } from '@/src/hooks/redux';

const useStyles = makeStyles()((theme: any) => ({
  tooltip: {
    position: 'relative',
    display: 'inline-grid',
    placeItems: 'center',
    borderRadius: '50%',
    marginRight: theme.spacing(2),
    '--size': theme.spacing(5),
    height: 'var(--size)',
    width: 'var(--size)',
    padding: 3,

    '& svg': {
      '--box-size': '16px',
      height: 'var(--box-size)',
      width: 'var(--box-size)',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },

    '&.tooltipTls': {
      backgroundColor: theme.palette.success.xlight,
      '&:hover': { backgroundColor: theme.palette.success.light },
      '& > a.link > svg': { fill: theme.palette.success.dark },
    },
  },
}));

type Props = {
  phoneNumber?: string;
  isMobile?: boolean;
  className?: string;
};

const ClickToCallDeprecated = (props: Props) => {
  const { phoneNumber, className, isMobile } = props;
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const practitioner = useSelector(practitionersSelectors.getItem);

  const { trackUserEvent } = useAnalyticsContext();

  if (!phoneNumber || !practitioner) return <span />;
  return (
    <Tooltip title={t('patientform_phonenumber_tooltip_call')} placement="top">
      <span
        className={cx(classes.tooltip, 'tooltipTls', className)}
        onClick={() => {
          trackUserEvent?.(GAActions.ClickCallTLS);
        }}
      >
        <a
          href={`tel:${phoneNumber}&x-fullname=${practitioner.firstName}+${practitioner.lastName}&x-id=${practitioner.id}`}
          className="link"
        >
          {isMobile ? (
            <FontAwesomeIcon name="mobile:regular" className="h-5 w-5" />
          ) : (
            <FontAwesomeIcon name="phone:regular" className="h-5 w-5" />
          )}
        </a>
      </span>
    </Tooltip>
  );
};

export default ClickToCallDeprecated;
