import { NextRouter, useRouter } from 'next/router';
import { toast } from '@kiiwi/ui';
import { AxiosError } from 'axios';
import { TFunction } from 'i18next';

import Routes from '../constants/routes';

import { useTranslation } from '@/i18n';
import { useSelector } from '@/src/hooks/redux';
import { MaiiaError } from '@/types/pro.types';

type QueryErrorHandler = (error: unknown) => void;

function isAxiosError(e: unknown): e is AxiosError {
  return !!(e as Maybe<AxiosError>)?.isAxiosError;
}

function handleError({
  statusCode,
  router,
  t,
}: {
  statusCode?: number;
  router: NextRouter;
  t: TFunction;
}) {
  switch (statusCode) {
    case 401:
      // Avoid redirection in case of authentication (mfa or standard)
      if (router.pathname !== Routes.LOGIN) {
        router.replace(Routes.LOGIN);
      }
      break;
    case 404:
    case 500:
      toast({
        variant: 'error',
        closable: true,
        description: t('error_boundary__server_error'),
      });
      break;
    default:
      break;
  }
}

function useHandleQueryErrors(): QueryErrorHandler {
  const router = useRouter();
  const { t } = useTranslation();

  return error => {
    if (!isAxiosError(error)) {
      return;
    }

    handleError({
      statusCode: error.response?.status,
      router,
      t,
    });
  };
}

function useHandleSagaErrors(): void {
  const sagaErrors = useSelector(state => state.errors);
  const { t } = useTranslation();
  const router = useRouter();

  const errors = Object.values(sagaErrors) as MaiiaError[];

  if (!errors.length) {
    return;
  }

  errors.forEach(error => {
    handleError({
      statusCode: error.status,
      router,
      t,
    });
  });
}

export { useHandleQueryErrors, useHandleSagaErrors };
