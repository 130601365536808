import { createSelector } from 'reselect';

import {
  ChatRoomDTO,
  ProAgendaSettingsDTO,
  User,
  UserChatRoomDTO,
} from '@maiia/model/generated/model/api-pro/api-pro';

import {
  findChatRoomIdByUsers,
  getCompleteUsers,
  getMessageAuthor,
} from '../utils/chat/chat.utils';
import agendaSettingsSelectors from './agendaSettingsSelectors';
import chatMessagesSelectors from './chatMessagesSelectors';
import chatRoomsSelectors from './chatRoomsSelectors';
import usersSelectors from './usersSelectors';

import { INFO } from '@/components/utils/constants';
import { ChatState } from '@/src/reducer';
import { RootState } from '@/src/reducer/rootState';
import { getUserAvatarUrl } from '@/src/utils';
import { ChatMessage } from '@/types/chat.types';
import { Dictionary } from '@/types/pro.types';

export const chatRoomWithIdSelector = createSelector(
  [
    chatRoomsSelectors.getItems,
    (_: RootState, chatRoomId: string) => chatRoomId,
  ],
  (chatRooms, chatRoomId) =>
    chatRooms.find(chatRoom => chatRoom.id === chatRoomId),
);

export const getChatRoomUsersById = createSelector(
  [(_: RootState, chatRoomId: string) => chatRoomId, chatRoomWithIdSelector],
  (_, chatRoom) => chatRoom?.users || [],
);

const getCurrentChatRoom = createSelector(
  [chatMessagesSelectors.getCurrentChatRoomId, chatRoomsSelectors.getItems],
  (currentChatRoomId, chatRooms) =>
    chatRooms.find(chatRoom => chatRoom.id === currentChatRoomId),
);

const isSelfChatRoomCondition = (chatRoom?: ChatRoomDTO, userId?: string) =>
  !!(
    chatRoom &&
    chatRoom.users.length === 1 &&
    chatRoom.users[0].id === userId
  );

const getSelfChatRoom = createSelector(
  [chatRoomsSelectors.getItems, usersSelectors.getItemId],
  (chatRooms, userId) =>
    chatRooms.find(chatRoom => isSelfChatRoomCondition(chatRoom, userId)),
);

const isSelfChatRoom = createSelector(
  [getCurrentChatRoom, usersSelectors.getItemId],
  (chatRoom, userId) => isSelfChatRoomCondition(chatRoom, userId),
);

const isMaiiaInfoChatRoom = createSelector(
  [getCurrentChatRoom],
  chatRoom => chatRoom?.type === INFO,
);

export const getCurrentChatRoomUsers = createSelector(
  getCurrentChatRoom,
  chatRoom => chatRoom?.users || [],
);

const getCurrentChatRoomsUsersWithAgendaSettings = createSelector(
  getCurrentChatRoomUsers,
  usersSelectors.getItems,
  agendaSettingsSelectors.getItems,
  (
    chatRoomUsers,
    users,
    agendaSettings,
  ): ((User | UserChatRoomDTO) & {
    agendaSettings: ProAgendaSettingsDTO | undefined;
  })[] => {
    const centerUsers = getCompleteUsers(users, chatRoomUsers);

    const otherUsers: UserChatRoomDTO[] = chatRoomUsers.filter(
      u => !centerUsers.find(centerUser => centerUser.id === u.id),
    );

    return [...centerUsers, ...otherUsers].map(user => ({
      ...user,
      agendaSettings: agendaSettings.find(
        agenda =>
          agenda.practitionerId ===
          (user as User)?.userProInformation?.defaultPractitionerId,
      ),
    }));
  },
);

export const getUnreadMessagesCount = (state: ChatState, chatRoomId: string) =>
  state.unreadMessages.items.find(message => message.chatRoomId === chatRoomId)
    ?.unreadMessageCount || 0;

export const getTotalUnReadMessagesByChatRoomId = (
  state: ChatState,
  chatRoomId: string,
) => {
  const { unreadMessageCount = 0, unreadThreadCount = 0 } =
    state.unreadMessages.items.find(
      message => message.chatRoomId === chatRoomId,
    ) || {};
  const totalUnreadMessages = unreadMessageCount + unreadThreadCount;

  return totalUnreadMessages;
};

const getCurrentChatRoomMessageList = createSelector(
  [
    (state: ChatState) => state.chatMessages.currentChatRoomId,
    (state: ChatState) => state.chatMessages.itemsDictMap,
  ],
  (chatRoomId, dictMap) =>
    Object.keys(dictMap[chatRoomId]?.itemsDict || {})
      .map(key => dictMap[chatRoomId]?.itemsDict[key] || [])
      .reduce((list, slice) => [...list, ...slice], [])
      .sort(
        (a: ChatMessage, b: ChatMessage) =>
          new Date(a.creationDate as string).getTime() -
          new Date(b.creationDate as string).getTime(),
      ),
);

const getCurrentChatRoomMessageListWithAgendaSetting = createSelector(
  [
    getCurrentChatRoomMessageList,
    usersSelectors.getItems,
    agendaSettingsSelectors.getItems,
  ],
  (rawMessages, users, agendaSettings) =>
    rawMessages.map((message: ChatMessage) => {
      const author = users.find(user => user.id === message.authorId);
      const agendaSetting = author?.userProInformation?.defaultPractitionerId
        ? agendaSettings.find(
            agenda =>
              agenda.practitionerId ===
              author?.userProInformation?.defaultPractitionerId,
          )
        : null;

      return {
        ...message,
        author,
        agendaSetting,
        isFirstUnreadMessage: false,
        isLatestMessage: false,
      };
    }),
);

export const chatRepliesSelector = createSelector(
  [
    (state: ChatState) => state.chatReplyMessages.itemsDictMap,
    (state: ChatState) => state.chatMessages.currentChatRoomId,
    (state: ChatState) => state.chatReplyMessages.item?.id,
  ],
  (itemsDictMap, chatRoomId, chatThreadId) =>
    (chatThreadId && itemsDictMap?.[chatRoomId]?.itemsDict?.[chatThreadId]) ||
    [],
);

export const makeChatRoomUsersDictSelector = (includeSelf: boolean = false) =>
  createSelector(
    [
      getCurrentChatRoomsUsersWithAgendaSettings,
      isSelfChatRoom,
      state => state.chatUsersConnected.items,
      state => state.users.item?.id,
    ],
    (chatRoomUsers, isSelf, connectedUsers, userId) =>
      chatRoomUsers
        .filter(user => includeSelf || isSelf || user?.id !== userId)
        .reduce(
          (dict, user) => ({
            ...dict,
            // ⚠️ WARN should do something about this below - check for falsyness above
            // the any is necessary for data-model retrocompatibility - it has no effect on runtime
            [(user as any)?.id]: {
              ...user,
              avatarUrl: getUserAvatarUrl(user, user.agendaSettings)!,
              isOnline: Boolean(
                connectedUsers.find(item => item?.id === user?.id),
              ),
            },
          }),
          {} as Dictionary<User & { avatarUrl: string; isOnline: boolean }>,
        ),
  );

export const getMessageById = createSelector(
  [
    (state: RootState) => state.chatMessages,
    (_: RootState, messageId: string | string[] | undefined) => messageId,
  ],

  (chatMessages, messageId) => {
    const { currentChatRoomId } = chatMessages;
    const allMessages = Object.keys(
      (currentChatRoomId &&
        chatMessages.itemsDictMap[currentChatRoomId]?.itemsDict) ||
        {},
    ).reduce((acc: ChatMessage[], key) => {
      return [
        ...acc,
        ...chatMessages.itemsDictMap[currentChatRoomId]?.itemsDict[key],
      ];
    }, []);
    return allMessages.find((message: ChatMessage) => message.id === messageId);
  },
);

export const chatThreadMessageSelector = createSelector(
  [
    (state: ChatState) => state.chatReplyMessages.item,
    (state: RootState) =>
      state.chatReplyMessages.item
        ? getMessageById(state, state.chatReplyMessages.item.id)
        : null,

    usersSelectors.getItems,
    chatRoomsSelectors.getOtherChatRoomUserList,
    agendaSettingsSelectors.getItems,
  ],
  (
    chatReplyMessageItem,
    chatMessage,
    users,
    otherChatRoomUsers,
    agendaSettings,
  ) => {
    if (chatReplyMessageItem) {
      const author = getMessageAuthor(
        chatReplyMessageItem.authorId,
        users,
        otherChatRoomUsers,
      );

      const repliers: (
        | User
        | UserChatRoomDTO
      )[] = chatReplyMessageItem.replyData
        ? users
            .filter(user =>
              chatReplyMessageItem?.replyData?.userIds?.includes(user.id),
            )
            .concat(
              otherChatRoomUsers.filter(user =>
                chatReplyMessageItem?.replyData?.userIds?.includes(user.id),
              ) || [],
            )
        : [];
      const agendaSetting = agendaSettings.find(
        agenda =>
          agenda.practitionerId ===
          (author as User)?.userProInformation?.defaultPractitionerId,
      );
      const value = {
        ...chatReplyMessageItem,
        ...chatMessage,
        agendaSetting,
        author,
        repliers,
      };
      return value;
    }
  },
);

export const selfChatRoomIdSelector = createSelector(
  (state: RootState) => state.users.item,
  (state: RootState) => state.chatRooms.items,
  (state: RootState) => state.centers.item?.id,
  (user, chatRooms: ChatRoomDTO[], centerId?: string) =>
    user ? findChatRoomIdByUsers(chatRooms, [user], centerId) : undefined,
);

export const getUnreadThreadCount = createSelector(
  [
    (state: ChatState) => state.users.item?.id,
    (state: ChatState) => state.chatThreads.items,
  ],
  (userId, threads) =>
    threads.reduce((count, thread) => {
      if (
        thread.replies.find(
          reply =>
            !reply?.users?.find(threadUser => threadUser.userId === userId)
              ?.isRead,
        )
      ) {
        return count + 1;
      }
      return count;
    }, 0),
);

const selectors = {
  name: 'chat',
  getCurrentChatRoom,
  isMaiiaInfoChatRoom,
  isSelfChatRoom,
  getSelfChatRoom,
  selfChatRoomIdSelector,
  getCurrentChatRoomUsers,
  getCurrentChatRoomsUsersWithAgendaSettings,
  getCurrentChatRoomMessageListWithAgendaSetting,
  getCurrentChatRoomMessageList,
  getUnreadMessagesCount,
  getTotalUnReadMessagesByChatRoomId,
  chatThreadMessageSelector,
  makeChatRoomUsersDictSelector,
  getUnreadThreadCount,
  getMessageById,
};

export type ChatSelector = typeof selectors;
export default selectors;
