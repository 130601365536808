import React, { ComponentType, forwardRef } from 'react';
import { Tooltip as MaterialTooltip, TooltipProps } from '@mui/material';

import { getDisplayName } from '@docavenue/components';

const Tooltip = MaterialTooltip;

/* Wrap an element in a Tooltip and a div.
 * The Tooltip component applies event handlers to its child element to handle events like hover.
 * But they are never triggered when the child is disabled because disabled elements do not trigger their event handlers.
 * So we add a div element in between.
 * See https://mui.com/material-ui/react-tooltip/#disabled-elements.
 */
export function withTooltip<T>(
  WrappedComponent: ComponentType<T>,
  tooltipProps: Omit<TooltipProps, 'children'>,
): ComponentType<T> {
  const WithToolTip = forwardRef<
    typeof WrappedComponent,
    ComponentProps<typeof WrappedComponent>
  >((props, ref) => (
    <Tooltip {...tooltipProps}>
      <div>
        <WrappedComponent ref={ref} {...props} />
      </div>
    </Tooltip>
  ));
  WithToolTip.displayName = `withTooltip(${getDisplayName(WrappedComponent)})`;
  return WithToolTip;
}

export default Tooltip;
