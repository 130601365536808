import React from 'react';
import { useDispatch } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import get from 'lodash/get';

import { Trans, useTranslation } from '../../../i18n';

import Timer from '@/components/atoms/Timer';
import Typography from '@/components/atoms/Typography';
import Dialog from '@/components/organisms/Dialog';
import { closeMiniVideoSession } from '@/src/actions/miniVideoSession';
import { useSelector } from '@/src/hooks/redux';
import { useTlcCenterAmc } from '@/src/hooks/selectors';
import { VideoSessionAggregate } from '@/types/pro.types';

type Props = {
  isOpen: boolean;
  handleRefuse: (event: React.MouseEvent<Element, MouseEvent>) => void;
  handleCancel: (event: React.MouseEvent<Element, MouseEvent>) => void;
  item: VideoSessionAggregate;
};

const RefusePatientDialog = (props: Props) => {
  const { isOpen, handleRefuse: refuse, handleCancel, item } = props;
  const { t } = useTranslation();
  const { isTlcCenterOrAmc } = useTlcCenterAmc();
  const isLoading = useSelector(
    state => state.loading[`videoSessions_${item.id}`],
  );
  const dispatch = useDispatch();

  const firstName = get(item, 'patient.firstName');
  const lastName = get(item, 'patient.lastName');

  const handleRefuse = (e: React.MouseEvent<Element, MouseEvent>) => {
    dispatch(closeMiniVideoSession());
    refuse(e);
  };

  const content = (
    <Trans
      i18nKey="common:refuse_patient_dialog_content"
      values={{ firstName, lastName }}
      components={[
        <Timer key="timer" date={item.creationDate} />,
        <br key="br" />,
      ]}
    />
  );
  return (
    <Dialog
      title={t('common:refuse_patient_dialog_title')}
      isOpen={isOpen}
      onClose={handleCancel}
      buttons={
        <>
          <Button variant="text" disabled={isLoading} onClick={handleCancel}>
            {t('common:cancel')}
          </Button>
          <LoadingButton
            onClick={handleRefuse}
            loading={isLoading}
            variant="contained"
            color="primary"
          >
            {t('common:yes_label')}
          </LoadingButton>
        </>
      }
    >
      <Typography variant="body">{content}</Typography>
      {isTlcCenterOrAmc && (
        <Typography variant="body">
          {t('common:refuse_patient_dialog_tlc_center_content')}
        </Typography>
      )}
    </Dialog>
  );
};

export default RefusePatientDialog;
