import Router, { NextRouter } from 'next/router';
import cookies from 'next-cookies';
import find from 'lodash/find';

import { encode } from 'querystring';
import { UrlObject } from 'url';

import { serialActions, storeActions } from '@docavenue/core';
import {
  CenterDTO,
  Practitioner,
  ProAgendaSettingsDTO,
  ProUserDTO,
} from '@maiia/model/generated/model/api-pro/api-pro';

import { getTlcCenterOrAmcAndHasCenterId } from '@/components/utils/tools';
import {
  agendaSettingsActions,
  agendaSettingsDefaultActions,
  centersActions,
  offsetActions,
  practitionersActions,
  usersActions,
  videoSessionsActions,
} from '@/src/actions';
import { isSubstituedListParams } from '@/src/actions/practitioners';
import {
  PENDING,
  SECRETARY,
  STARTED,
  TELESECRETARY,
  WAITING,
} from '@/src/constants';
import Routes from '@/src/constants/routes';
import Tabs from '@/src/constants/tabs';
import { isomorphicRedirect } from '@/src/hoc/securedPageFunctions';
import { RootState } from '@/src/reducer/rootState';
import { practitionersSelectors } from '@/src/selector';
import { MaiiaNextPageContext } from '@/src/types/MaiiaNextContext';
import { filterFalsyValues, getOffset, isConnectPathname } from '@/src/utils';
import { isAuthPath, requiresPractitionerId } from '@/src/utils/RoutesUtils';

function redirectToAgendaCenter(
  state: RootState,
  {
    practitionerId,
    ctx,
    router,
  }: {
    practitionerId: string | null;
    ctx: MaiiaNextPageContext;
    router: NextRouter;
  },
) {
  const { pathname, query } = ctx;
  const agendaSettings = state.agendaSettings.item;

  if (
    agendaSettings &&
    !isConnectPathname(pathname) &&
    !agendaSettings.isAgenda
  ) {
    const alternativeAgendaSettings = practitionerId
      ? find(state.agendaSettingsDefault.items, {
          practitionerId,
          isAgenda: true,
        })
      : null;

    if (alternativeAgendaSettings) {
      router.push(
        {
          pathname,
          query: {
            ...query,
            centerId: alternativeAgendaSettings.centerId,
          },
        },
        undefined,
        { shallow: true },
      );
    } else {
      router.replace(Routes.CHAT);
    }
  }
}

function resetStore({
  state,
  pathname,
  tab,
  centerIdState,
  practitionerIdState,
}: {
  state: RootState;
  pathname: string;
  tab: string | null;
  centerIdState?: string;
  practitionerIdState?: string;
}) {
  if (
    centerIdState &&
    (practitionersSelectors.getItemId(state) !== practitionerIdState ||
      state?.centers.item?.id !== centerIdState)
  ) {
    const toKeepConditionallyMap = {
      appointmentsTlc: pathname === Routes.TRANSACTIONS,
      actionsHistory:
        pathname === Routes.ADMINISTRATION && tab === Tabs.ACTIVITY,
      documentsHistory:
        pathname === Routes.ADMINISTRATION && tab === Tabs.DOCUMENTS,
      availabilities: pathname === Routes.CREATE_APPOINTMENT,
      complexForm: pathname === Routes.CREATE_APPOINTMENT,
      complexFormData: pathname === Routes.CREATE_APPOINTMENT,
      consultationReasons:
        pathname === Routes.SETTINGS &&
        (tab === Tabs.MOTIFS ||
          tab === Tabs.INSTRUCTIONS ||
          tab === Tabs.INSTRUCTIONS_BORNE),
      consultationReasonsParent:
        pathname === Routes.SETTINGS && tab === Tabs.MOTIFS,
      notificationSettings:
        pathname === Routes.SETTINGS && tab === Tabs.NOTIFICATIONS,
      profiles: pathname === Routes.SETTINGS && (!tab || tab === Tabs.PROFILE),
      conventionedActs:
        pathname === Routes.SETTINGS && (!tab || tab === Tabs.PROFILE),
      appointmentsHistory: pathname === Routes.CREATE_APPOINTMENT,
      profilesParent:
        pathname === Routes.SETTINGS && (!tab || tab === Tabs.PROFILE),
      resourcesCenter:
        pathname === Routes.SETTINGS &&
        (tab === Tabs.RESOURCES || tab === Tabs.MOTIFS),
      resources:
        pathname === Routes.SETTINGS &&
        (tab === Tabs.RESOURCES || tab === Tabs.MOTIFS),
      patientInstructions:
        pathname === Routes.SETTINGS && tab === Tabs.INSTRUCTIONS,
      secretaryInstructions:
        pathname === Routes.SETTINGS && tab === Tabs.SECRETARIAT_INSTRUCTIONS,
      secretaryInstructionCategories:
        pathname === Routes.SETTINGS && tab === Tabs.SECRETARIAT_INSTRUCTIONS,
    };
    const toKeepConditionally = Object.keys(toKeepConditionallyMap).reduce(
      (list, slice) =>
        toKeepConditionallyMap[slice] ? [...list, slice] : list,
      [] as string[],
    );

    return storeActions.resetStore({
      toKeepStates: [
        'agendaSettings',
        'agendaSettingsDefault',
        'appointments',
        'appointmentsNotes',
        'authentication',
        'calendar',
        'centers',
        'centersChat',
        'chatMessages',
        'chatRooms',
        'chatUsersConnected',
        'chatUsersTyping',
        'documents',
        'expertises',
        'invitationSuggestions',
        'loading',
        'notificationSettingsTokens',
        'patients',
        'practitioners',
        'statistics',
        'timeSlots',
        'users',
        'videoSessions',
        'websocket',
        'weekTemplateCycles',
        'unreadMessages',
        'resourcesTimeSlot',
        'resourcesTimeSlotGroup',
        'searchInvitation',
        'chatInvitations',
        'rightsCenter',
        'userPractitioners',
        ...toKeepConditionally,
      ].filter(e => e),
    });
  }
}

function getTeleconsultationVideoSessionsByDefaultPractitionerId({
  state,
  centerId,
}: {
  state: RootState;
  centerId: string | null;
}) {
  const defaultPractitionerId = centerId
    ? find(state.agendaSettingsDefault.items, {
        centerId,
      })?.practitionerId
    : null;

  const agendaSettingsDefaultsWithTlc = state.agendaSettingsDefault.items.filter(
    agendaSettingsDefault => agendaSettingsDefault.isTeleconsultation,
  );

  const isTlcCenterOrAmcAndHasCenterId = getTlcCenterOrAmcAndHasCenterId(
    state,
    centerId,
  );

  if (
    agendaSettingsDefaultsWithTlc.length &&
    centerId &&
    defaultPractitionerId
  ) {
    return videoSessionsActions.getList({
      ...(isTlcCenterOrAmcAndHasCenterId && { centerId }),
      practitionerId: defaultPractitionerId,
      statuses: [PENDING, WAITING, STARTED].join(','),
      aggregateWith: 'patient,consultationReason,teleconsultationRelay',
    });
  }
}

function getCenter({
  state,
  centerId,
  centerIdState,
}: {
  state: RootState;
  centerId: string | null;
  centerIdState: string | undefined;
}) {
  const centers = state.users.item?.userProInformation?.associatedCenters || [];
  const urlCenter = centerId ? find(centers, { centerId }) : null;
  if (!urlCenter) {
    // check if lastVisitedCenter exists on user object
    const lastVisitedCenterId =
      state.users.item?.userProInformation?.lastVisitedCenterId;
    const lastVisitedCenter = find(centers, {
      centerId: lastVisitedCenterId,
    });

    const center =
      lastVisitedCenter ||
      find(centers, { isDefaultCenter: true }) ||
      centers[0] ||
      {};

    if (center.centerId) {
      return centersActions.getOne(center.centerId, {
        params: { isWithExternalSyncCenters: true },
      });
    }
  } else if (urlCenter.centerId && urlCenter.centerId !== centerIdState) {
    return centersActions.getOne(urlCenter.centerId, {
      params: { isWithExternalSyncCenters: true },
    });
  }
}

function getPractitionersList({
  state,
  rootCenterId,
  rootCenterIdState,
  centerIdState,
}: {
  state: RootState;
  rootCenterId: string | null;
  rootCenterIdState?: string;
  centerIdState?: string;
}) {
  const isCenterIdChanged = state.centers.item?.id !== centerIdState;
  const hasNoPractitioners =
    practitionersSelectors.getItems(state).length === 0;
  const isRootCenterIdChanged = rootCenterId !== rootCenterIdState;
  const hasCenterId = !!state.centers.item?.id;
  const hasRootCenterId = !!rootCenterId;

  if (
    (isCenterIdChanged || hasNoPractitioners || isRootCenterIdChanged) &&
    (hasCenterId || hasRootCenterId)
  ) {
    const isSubstitute =
      state.users.item?.role?.name === 'SUBSTITUTE_PRACTITIONER';

    const practitionersParams = {
      centerId: rootCenterId || state.centers.item?.id || '',
      recursive: !!rootCenterId,
    };

    const substitutedListParams = {
      ...practitionersParams,
      userId: state.users.item?.id,
    };

    if (isSubstitute && isSubstituedListParams(substitutedListParams)) {
      return practitionersActions.getSubstitutedList(substitutedListParams);
    }
    return practitionersActions.getList(practitionersParams);
  }
}

/**
 * Retrieves agenda settings based on the current and default practitioner IDs, center, and root center states.
 */
function getSelectedOrDefaultAgendaSettings({
  state,
  practitionerId,
  centerId,
  rootCenterId,
  centerIdState,
  rootCenterIdState,
}: {
  state: RootState;
  practitionerId: string | null;
  centerId: string | null;
  rootCenterId: string | null;
  centerIdState?: string;
  rootCenterIdState?: string;
}) {
  const defaultPractitionerId =
    state?.users?.item?.userProInformation?.defaultPractitionerId;
  const currentPractitionerId = practitionerId ?? defaultPractitionerId;

  const hasCenterChanged = centerId !== centerIdState;
  const hasRootCenterChanged = rootCenterId !== rootCenterIdState;
  const hasAgendaSettings = state.agendaSettings.items.length;
  const targetCenterId = rootCenterId || state.centers.item?.id;
  if (
    !rootCenterId &&
    targetCenterId &&
    targetCenterId !== rootCenterId &&
    (hasCenterChanged || hasRootCenterChanged || !hasAgendaSettings)
  ) {
    const practitionerIds = [
      currentPractitionerId,
      defaultPractitionerId,
    ].filter(filterFalsyValues);

    if (!practitionerIds.length) return null;

    const payload = {
      centerId: targetCenterId,
      recursive: !!rootCenterId,
      practitionerIds,
    };

    return agendaSettingsActions.getList(payload);
  }

  return null;
}

function selectAgendaSettings({
  state,
  practitionerId,
  centerId,
}: {
  state: RootState;
  practitionerId: string | null;
  centerId: string | null;
}) {
  const agendaSettingsState = state.agendaSettings.item;

  if (
    practitionerId &&
    centerId &&
    (agendaSettingsState?.centerId !== centerId ||
      agendaSettingsState?.practitionerId !== practitionerId)
  ) {
    const agendaSettings =
      find(state.agendaSettings.items, {
        practitionerId,
        centerId,
      }) || state.agendaSettings.items[0];

    if (agendaSettings) {
      return agendaSettingsActions.setItem(agendaSettings);
    }
  }
}

function selectPractitioner({
  state,
  pathname,
  practitionerId,
}: {
  state: RootState;
  pathname: string;
  practitionerId: string | null;
}) {
  // Check if practitionerId is not provided and it is not required for the given pathname
  if (!practitionerId && !requiresPractitionerId(pathname)) {
    return practitionersActions.setItem(null);
  }

  const isAgendaSettingsNotFound =
    state.agendaSettings.error && state.agendaSettings.error.status === 404;

  const practitioners = practitionersSelectors.getItems(state);

  const practitioner: Practitioner | null =
    (practitionerId &&
      find(practitioners, {
        id: practitionerId,
      })) ||
    null;

  // Initial selection of a practitioner
  let selectedPractitioner =
    practitioner || state.agendaSettings.item?.practitioner || null;

  // If agenda settings are not found, select a default practitioner
  if (isAgendaSettingsNotFound) {
    selectedPractitioner =
      find(practitioners, {
        id: state?.users?.item?.userProInformation?.defaultPractitionerId,
      }) || practitioners?.[0];
  }

  return practitionersActions.setItem(selectedPractitioner);
}

function handlePractitionerStateUpdate({
  state,
  pathname,
  practitionerId,
  centerId,
  ctx,
  query,
}: {
  state: RootState;
  pathname: string;
  practitionerId: string | null;
  centerId: string | null;
  ctx: MaiiaNextPageContext;
  query: UrlObject;
}) {
  const newCenterIdState = state.centers.item?.id || '';
  const isTlsOrSecretary = [TELESECRETARY, SECRETARY].includes(
    state.users.item?.role.name || '',
  );
  const isAgendaSettingsNotFound =
    state.agendaSettings.error && state.agendaSettings.error.status === 404;

  let newPractitionerIdState = practitionersSelectors.getItemId(state) || '';

  if (
    !isAgendaSettingsNotFound &&
    practitionerId === state.agendaSettings.item?.practitionerId
  ) {
    // Given the fact that agenda settings should be updated first
    // we check that current agendaSettings.practitionerId fits the practitionerId param before setting it.
    newPractitionerIdState = state.agendaSettings.item?.practitionerId || '';
  }

  if (!newPractitionerIdState) {
    newPractitionerIdState =
      state?.users?.item?.userProInformation?.defaultPractitionerId || '';
  }

  if (!requiresPractitionerId(pathname) && !practitionerId) {
    newPractitionerIdState = '';
  }

  // If a practitioner ID is required but not specified, default to the first one available
  if (!newPractitionerIdState && requiresPractitionerId(pathname)) {
    newPractitionerIdState = state.practitioners.items[0]?.id || '';
  }

  // Check if the center or practitioner IDs have changed
  if (
    newCenterIdState !== centerId ||
    newPractitionerIdState !== practitionerId
  ) {
    const { multiAgenda } = cookies(ctx);

    const newQuery =
      practitionerId === undefined &&
      centerId === undefined &&
      isTlsOrSecretary &&
      multiAgenda
        ? { ...query, ...JSON.parse(multiAgenda) }
        : {
            ...query,
            practitionerId: newPractitionerIdState,
            centerId: newCenterIdState,
          };

    isomorphicRedirect(
      {
        pathname,
        query: { ...Router.query, ...newQuery },
      },
      ctx,
    );

    return serialActions.break();
  }
}

function updateLastVisitedCenter(userId: string, centerId: string) {
  return usersActions.updateOne(
    { id: userId },
    {
      chunkResource: `last-visited-center/${centerId}`,
    },
  );
}

function breakIfNoActionsRequired({
  state,
  pathname,
  centerId,
  practitionerId,
}: {
  state: RootState;
  pathname: string;
  centerId: string | null;
  practitionerId: string | null;
}) {
  const centerChanged = state.centers.item?.id !== centerId;
  const practitionerChanged =
    practitionersSelectors.getItemId(state) !== practitionerId;
  if (practitionerId && centerId && !centerChanged && !practitionerChanged) {
    return serialActions.break();
  }

  if (isAuthPath(pathname)) return serialActions.break();
  if (!state.users.item) return serialActions.break();
}

function processTimeZoneOffset(center: CenterDTO | null) {
  const offset = getOffset(center);
  return offsetActions.setOffset({ offset });
}

function getAgendaSettingDefault({
  agendaSettingsDefaultsState,
  user,
}: {
  agendaSettingsDefaultsState: ProAgendaSettingsDTO[];
  user: ProUserDTO | null;
}) {
  const defaultPractitionerId = user?.userProInformation?.defaultPractitionerId;

  if (
    defaultPractitionerId &&
    (!agendaSettingsDefaultsState ||
      !agendaSettingsDefaultsState.some(
        a => a.practitionerId === defaultPractitionerId,
      ))
  ) {
    return agendaSettingsDefaultActions.getList({
      practitionerId: defaultPractitionerId,
      aggregateWith: 'teleconsultationUser',
    });
  }
  if (
    user &&
    user.role?.name === 'SUBSTITUTE_PRACTITIONER' &&
    !user.userProInformation?.substitutedPractitioners?.every(practitioner =>
      agendaSettingsDefaultsState.find(
        agendaSettings =>
          practitioner.practitionerId === agendaSettings.practitionerId &&
          practitioner.centerId === agendaSettings.centerId,
      ),
    )
  ) {
    return agendaSettingsDefaultActions.getSubstitutedList({
      userId: user.id,
      aggregateWith: 'teleconsultationUser',
    });
  }
}

async function runSerialActionsWithRoutingContext({
  ctx,
  router,
}: {
  ctx: MaiiaNextPageContext;
  router: NextRouter;
}) {
  const { pathname, query, store } = ctx;
  const searchParams = new URLSearchParams(encode(query));
  const practitionerId = searchParams.get('practitionerId');
  const centerId = searchParams.get('centerId');
  const tab = searchParams.get('tab');
  const rootCenterId = searchParams.get('rootCenterId');

  const centerIdState = store.getState().centers.item?.id;
  const rootCenterIdState = store.getState().centers.rootCenterId;
  const practitionerIdState = practitionersSelectors.getItemId(
    store.getState(),
  );

  store.dispatch(
    // At the first error, the flow breaks (including bad API returns or timeouts)
    serialActions.serial(
      [
        (state: RootState) =>
          breakIfNoActionsRequired({
            state,
            pathname,
            centerId,
            practitionerId,
          }),

        (state: RootState) =>
          getAgendaSettingDefault({
            agendaSettingsDefaultsState: state.agendaSettingsDefault.items,
            user: state.users.item,
          }),
        (state: RootState) => getCenter({ state, centerId, centerIdState }),

        // Save root center id in state to avoid unnecessary data fetches
        () => centersActions.setRootCenterId(rootCenterId || null),

        (state: RootState) => processTimeZoneOffset(state.centers.item),

        (state: RootState) =>
          getPractitionersList({
            state,
            rootCenterId,
            centerIdState,
            rootCenterIdState,
          }),

        (state: RootState) =>
          getSelectedOrDefaultAgendaSettings({
            state,
            practitionerId,
            centerId,
            centerIdState,
            rootCenterId,
            rootCenterIdState,
          }),

        (state: RootState) =>
          selectAgendaSettings({
            state,
            centerId,
            practitionerId,
          }),

        (state: RootState) => {
          redirectToAgendaCenter(state, {
            ctx,
            practitionerId,
            router,
          });
        },

        // Set the current pracitioner based on query params or the first of the list, can be null for some url (ex: /settings)
        (state: RootState) =>
          selectPractitioner({ state, pathname, practitionerId }),

        (state: RootState) =>
          resetStore({
            state,
            pathname,
            tab,
            centerIdState,
            practitionerIdState,
          }),

        (state: RootState) =>
          handlePractitionerStateUpdate({
            ctx,
            state,
            pathname,
            query,
            centerId,
            practitionerId,
          }),

        (state: RootState) =>
          getTeleconsultationVideoSessionsByDefaultPractitionerId({
            state,
            centerId,
          }),
        // Save center as last visited center
        (state: RootState) => {
          if (state.users.item?.id && state.centers.item?.id) {
            return updateLastVisitedCenter(
              state.users.item.id,
              state.centers.item.id,
            );
          }
        },
      ],
      false,
    ),
  );
}

export { runSerialActionsWithRoutingContext };
