import React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useTranslation } from '../../../i18n';
import { KEY_LANG } from '../../../src/constants';
import {
  useIsPractitioner,
  useIsSecretary,
  useIsTelesecretary,
} from '../../../src/hooks/selectors';
import { useIsProductionEnvironment } from '../../../src/hooks/utils';
import { practitionersSelectors } from '../../../src/selector';
import Chip from '../../atoms/Chip';
import BrandNameConnect from '../../icons/BrandNameConnect';
import HeaderNotificationsDialog from '../HeaderNotificationsDialog/HeaderNotificationsDialog';
import HeaderSearchAutocomplete from '../HeaderSearchAutocomplete/HeaderSearchAutocomplete';
import NavMenu from '../NavMenu';
import IconsMenuFreemium from './IconsMenuFreemium';

import UserMenu from '@/components/organisms/UserMenu';
import { DatacyContext } from '@/src/contexts';
import { useSelector } from '@/src/hooks/redux';

const useStyles = makeStyles()((theme: any) => ({
  headerIsFreemiumRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    height: theme.layout.headerHeightPx,
    gap: theme.spacing(5),
    alignItems: 'center',
    background: theme.palette.charte.gradient,

    '& > * ': {
      flexBasis: '33%',
    },

    '& .leftSide': {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(4),
      gap: theme.spacing(4),
      '& .demoChip': {
        ...theme.components.header.demoChip,
      },
      '& .brandNameConnect': {
        height: 40,
        width: 'auto',
      },
      '& .lang': {
        marginRight: theme.spacing(4),
        '& .langBtn': {
          border: `1px solid ${theme.palette.common.white}`,
          padding: '3px 24px',
          backgroundColor: 'transparent',
          '& p': {
            textTransform: 'uppercase',
            fontSize: theme.size.font.md,
          },
        },
      },
    },

    '& .search': {
      '& .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root': {
        color: 'white',
        backgroundColor: theme.components.header.search.backgroundColor,
        borderRadius: theme.spacing(1),
        paddingLeft: theme.spacing(4),
      },

      '& input::placeholder': {
        color: 'white',
      },
    },

    '& .rightSide': {
      display: 'flex',
      justifyContent: ' flex-end',
      alignItems: 'center',
      '--size': '35px',

      '& button': {
        padding: 5,
        '& svg': {
          color: theme.palette.common.white,
        },
      },

      '& .bg-freemium-menu': {
        backgroundColor: theme.palette.background.headerIconButton, // I dont have this color in kiiwi palette
      },
      '& *[gridkey="notifications"]': {
        borderRadius: '50%',
        color: theme.palette.common.white,
      },
    },
  },
}));

const setLocal = () => {
  const lang = window.localStorage.getItem(KEY_LANG) || 'fr';

  window.localStorage.setItem(KEY_LANG, lang === 'fr' ? 'en' : 'fr');
  window.location.href = window.location.href;
};

const HeaderIsFreemium = () => {
  const isProductionEnvironment = useIsProductionEnvironment();
  const isPractitioner = useIsPractitioner();
  const isSecretary = useIsSecretary();
  const isTls = useIsTelesecretary();
  const { classes } = useStyles();
  const practitioner = useSelector(practitionersSelectors.getItem);
  const { t } = useTranslation();

  const lang =
    (typeof window !== 'undefined' && window.localStorage.getItem(KEY_LANG)) ||
    'fr';

  return (
    <DatacyContext.Provider value="header">
      <div className={classes.headerIsFreemiumRoot}>
        <div className="leftSide">
          <BrandNameConnect />
          {practitioner?.isDemo && (
            <Chip label={t('common:demo_flag')} className="demoChip" />
          )}
          <div className="lang">
            {!isProductionEnvironment && (
              <Button
                variant="contained"
                className="langBtn"
                color="secondary"
                onClick={setLocal}
              >
                {lang}
              </Button>
            )}
          </div>
        </div>
        <div className="search">
          <HeaderSearchAutocomplete />
        </div>
        <div className="rightSide">
          <IconsMenuFreemium />
          {(isPractitioner || isSecretary || isTls) && (
            <Button>
              <div gridkey="notifications">
                <HeaderNotificationsDialog iconClassname="h-7 w-7" />
              </div>
            </Button>
          )}
          <NavMenu />
          <UserMenu />
        </div>
      </div>
    </DatacyContext.Provider>
  );
};

export default HeaderIsFreemium;
