/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */

import { Address } from 'cluster';

import {
  actionsGenerationHelperV2,
  loadingActions,
  websocketActions,
} from '@docavenue/core';
// it's ok to disable the line below because it's only type import - todo find a way to export types in built packages
// eslint-disable-next-line no-restricted-imports
import { ActionTemplateType } from '@docavenue/core/src/types';
import {
  AppointmentGetDTO,
  Availability,
  AvailablePractitionersForImmediateTlcCenterDTO,
  Center,
  ChatRoomDTO,
  ConsultationReason,
  ConsultationReasonGroupLabel,
  ContactDTO,
  ConventionedActDTO,
  Expertise,
  FeedbackTlc,
  MedicalContextDTO,
  OfficeInformation,
  Patient,
  PhoneValidityDTO,
  Picture,
  Practitioner,
  PractitionerDTO,
  ProAgendaSettingsDTO,
  ProfessionalCardDTO,
  ProSecretaryInstructionCategoryGetDTO,
  ProSecretaryInstructionGetDTO,
  ProStatisticDTO,
  ProUserDTO,
  PublicInformation,
  ResourceDTO,
  Speciality,
  SubstituteData,
  TeleconsultationReminder,
  TimeSlotGetDTO,
  TimeSlotResourceDTO,
  TimeSlotResourceGroupDTO,
  TrackingProfileGetDTO,
  UnreadMessageDTO,
  User,
  UserResetPasswordDTO,
  WeekTemplateCycle,
} from '@maiia/model/generated/model/api-pro/api-pro';

import { Person } from '../../components/permissionsNew/PermissionsNew.d';
import {
  PatchUserFobiddenPractitionersDTO,
  PatchUserRightDTO,
  PatchUserRoleDTO,
} from '../../components/permissionsNew/PermissionsNewRequests';
import {
  ClearAccessRequest,
  Document,
  DocumentViewerItem,
  NotificationsCenter,
  VideoSessionAggregate,
} from '../../types/pro.types';
import { PRO } from '../constants';
import { Prescription } from '../reducer/prescription';
import {
  ChatOnBoardingInformation,
  OnBoardingInformation,
} from '../reducer/rootState';
import acknowledgeDocumentsActions from './acknowledgeDocuments';
import appointmentsActions from './appointments';
import appointmentsConflictsActions from './appointmentsConflicts';
import appointmentsTlcActions from './appointmentsTlc';
import associationCodeActions from './associationCode';
import authenticationActions from './authentification';
import calendarActions from './calendar';
import centersActions from './centers';
import chatUtilsActions from './chatUtils';
import complexFormActions from './complexForm';
import confirmLogoutActions from './confirmLogout';
import consultationReasonsActions from './consultationReasons';
import consultationReasonsParentActions from './consultationReasonsParent';
import dialogActions from './dialog';
import exportStatisticsDocumentActions from './exportStatisticsDocument';
import loaderMaiiaActions from './loaderMaiia';
import mergePatientsActions from './mergePatients';
import notificationCentersActions from './notificationCenters';
import offsetActions from './offset';
import phoneCheckActions from './phoneCheck';
import practitionersActions from './practitioners';
import practitionersTimeSlotsActions from './practitionersTimeSlots';
import prescriptionActions from './prescription';
import resourcesActions from './resources';
import resourcesCenterActions from './resourcesCenter';
import resourcesTimeSlotActions from './resourcesTimeSlot';
import resourcesTimeSlotGroupActions from './resourcesTimeSlotGroup';
import snacksActions from './snacks';
import teleconsultationActions from './teleconsultation';
import timeSlotResourcesCalendarActions from './timeSlotResourcesCalendar';
import timeSlotsActions from './timeSlots';
import treatmentsActions, { ActionTreatment } from './treatments';
import videoProMeetingActions, {
  VideoProMeetingActions,
} from './videoProMeetingActions';
import videoSessionsActions from './videoSessions';

import { Actions } from '@/src/actions/actionsTypes';
import {
  ChatDraftEditorSubmitProps,
  ChatRoomDocument,
} from '@/types/chat.types';

export { default as agendaSettingsActions } from './agendaSettings';
export { default as agendaSettingsDefaultActions } from './agendaSettingsDefault';
export { default as chatInvitationsActions } from './chatInvitations';
export { default as chatMessagesActions } from './chatMessages';
export { default as chatReplyMessagesActions } from './chatReplyMessages';
export { default as chatThreadsActions } from './chatThreads';
export * from './constants';
export { default as usersActions } from './users';

export type Search = Center | Patient | Practitioner;

export type CrudActions = {
  phoneCheckActions: Actions<PhoneValidityDTO>;
  invitationSuggestionsActions: Actions<ProfessionalCardDTO>;
  actionsHistoryActions: Actions<Object>; // change with correct type
  agendaSettingsCentersActions: Actions<ProAgendaSettingsDTO>;
  appointmentsAbsencesConflictsActions: Actions<AppointmentGetDTO>;
  appointmentsActions: Actions<AppointmentGetDTO>;
  appointmentsHistoryActions: Actions<AppointmentGetDTO>;
  appointmentsNotesActions: Actions<AppointmentGetDTO>;
  authenticationActions: Actions<{
    token: string;
    refresh: string;
    type: typeof PRO;
    applicationType: string;
    operatorId?: string;
    tlsFirstName?: string;
    tlsLastName?: string;
  }>;
  autoOnBoardingActions: Actions<OnBoardingInformation>;
  associationCodeActions: Actions<any>; // 'any' here because nothing is returned from the back
  availabilitiesActions: Actions<Availability>;
  availablePractitionersActions: Actions<
    AvailablePractitionersForImmediateTlcCenterDTO
  >;
  bcbDosagesActions: Actions<Object>;
  Actions: Actions<Object>;
  buildActions: Actions<{
    id: number;
    releaseVersion: string;
    reload: boolean;
  }>;
  centersActions: Actions<Center>;
  centersChatActions: Actions<Center>;
  centersParentActions: Actions<Center>;
  clearAccessRequestActions: Actions<ClearAccessRequest | string>;
  complexFormDataActions: Actions<any>;
  consultationReasonsComplexFormActions: Actions<ConsultationReason>;
  consultationReasonsComplexFormRootActions: Actions<ConsultationReason>;
  consultationTypesActions: Actions<string>;
  contactsActions: Actions<ContactDTO>;
  creatableConsultationReasonsActions: Actions<ConsultationReason>;
  creatableConsultationReasonsGroupsActions: Actions<
    ConsultationReasonGroupLabel
  >;
  documentsActions: Actions<DocumentViewerItem>;
  documentsHistoryActions: Actions<Document>;
  expertisesActions: Actions<Expertise>;
  exportStatisticsDocumentActions: Actions<Document>;
  feedbackTLCActions: Actions<FeedbackTlc>;
  leaveCentersActions: Actions<Center>;
  leaveCentersRootActions: Actions<Center>;
  logoutActions: Actions<null>;
  nextAvailabilitiesActions: Actions<Availability>;
  notificationSettingsActions: Actions<Notification>;
  notificationSettingsTokensActions: Actions<string>;
  notificationCentersActions: Actions<NotificationsCenter>;
  omnidocDocumentsActions: Actions<Document>;
  onfidoActions: Actions<any>;
  passwordChangesActions: Actions<{
    oldPassword: string;
    newPassword: string;
    newPasswordConfirmation: string;
    userType: typeof PRO;
  }>; // change with correct type
  passwordRequestsActions: Actions<{ email: string; userType: string }>; // change with correct type
  passwordResetActions: Actions<UserResetPasswordDTO>;
  passwordResetTokenActions: Actions<Object>; // change with correct type
  patientsActions: Actions<Patient>;
  paymentsActions: Actions<{ amountToCapture: number; appointmentId: string }>;
  pendingAppointmentsActions: Actions<AppointmentGetDTO>;
  practitionersActions: Actions<Practitioner>;
  practitionersComplexFormActions: Actions<Practitioner>;
  practitionersComplexFormRootActions: Actions<Practitioner>;
  practitionersDefaultActions: Actions<PractitionerDTO>;
  practitionersTimeSlotsActions: Actions<TimeSlotGetDTO>;
  profilePicturesActions: Actions<Address & OfficeInformation & Picture>;
  profilesActions: Actions<PublicInformation>;
  profilesParentActions: Actions<Address & OfficeInformation>;
  resourceLabelsActions: Actions<ResourceDTO>;
  resourcesTimeSlotActions: Actions<TimeSlotResourceDTO>;
  resourcesTimeSlotGroupActions: Actions<TimeSlotResourceGroupDTO>;
  timeSlotResourcesCalendarActions: Actions<TimeSlotGetDTO>;
  substitutePractitionersActions: Actions<User | SubstituteData>;
  searchActions: Actions<Search>;
  shareDocumentsActions: Actions<{
    destinationProfileIds: string[];
    documentId: string;
  }>;
  statisticsActions: Actions<ProStatisticDTO>;
  specialitiesActions: Actions<Speciality>;
  specialitiesComplexFormActions: Actions<Speciality>;
  specialitiesComplexFormRootActions: Actions<Speciality>;
  teleconsultationRemindersActions: Actions<TeleconsultationReminder>;
  timeSlotsActions: Actions<TimeSlotGetDTO>;
  treatmentsActions: ActionTreatment;
  userPractitionersActions: Actions<User>;
  videoSessionsActions: Actions<VideoSessionAggregate>;
  weekTemplateCyclesActions: Actions<WeekTemplateCycle>;
  weekTemplateCyclesConflictsActions: Actions<WeekTemplateCycle>;

  prescriptionActions: Actions<Prescription>;
  posologiesActions: Actions<any>;
  chatOnBoardingActions: Actions<ChatOnBoardingInformation>;
  verifyIdentityActions: Actions<boolean>;
  existingEmailActions: Actions<boolean>;
  // resendCodeActions,
  confirmCodeActions: Actions<{
    code: string;
    country: string;
    isResetPhoneNumber: boolean;
    phoneNumber: string;
  }>;
  resendCodeActions: Actions<any>;
  teleconsultationActions: Actions<{
    autoSuggestion: boolean;
    nextSuggestionTime: string;
    startDate: string;
    endDate: string;
  }>;
  recurringAvailabilitiesActions: Actions<Availability>;
  videoProMeetingActions: VideoProMeetingActions;
  rightsCenterActions: Actions<
    | Person
    | PatchUserRightDTO
    | PatchUserRoleDTO
    | PatchUserFobiddenPractitionersDTO
  >;
  assistedTLCActions: Actions<any>;
  patientReferralActions: Actions<any>;
  conventionedActsActions: Actions<ConventionedActDTO>;
  medicalContextsActions: Actions<MedicalContextDTO>;
  secretaryInstructionsActions: Actions<ProSecretaryInstructionGetDTO>;
  secretaryInstructionCategoriesActions: Actions<
    ProSecretaryInstructionCategoryGetDTO
  >;
};

export type ChatActions = {
  chatRoomDocumentsActions: Actions<ChatRoomDocument>;
  agendaSettingsActions: Actions<ProAgendaSettingsDTO>;
  agendaSettingsDefaultActions: Actions<ProAgendaSettingsDTO>;
  chatDraftsActions: Actions<ChatDraftEditorSubmitProps>;
  chatRoomsActions: Actions<ChatRoomDTO>;
  chatUsersTypingActions: Actions<User>;
  trackingProfileActions: Actions<TrackingProfileGetDTO>;
  patientsActions: Actions<Patient>;
  usersActions: Actions<ProUserDTO>;
  unreadMessagesActions: Actions<UnreadMessageDTO>;
  searchInvitationActions: Actions<ProfessionalCardDTO>;
};

const actionCrudV2 = actionsGenerationHelperV2(
  [
    {
      resource: 'invitationSuggestions',
      chunkUrlResource: 'chat-invitation-suggestions',
    },
    {
      resource: 'build',
    },
    {
      resource: 'bcbDosages',
      scope: 'bcb',
    },
    {
      resource: 'authentication',
      chunkUrlResource: 'login',
      scope: 'auth',
    },
    {
      resource: 'autoOnBoarding',
      chunkUrlResource: 'auto-on-boarding',
      scope: 'pro-public',
    },
    {
      resource: 'agendaSettingsCenters',
      chunkUrlResource: 'agendaSettings',
    },
    {
      resource: 'appointmentsHistory',
      chunkUrlResource: 'appointments-history',
    },
    {
      resource: 'actionsHistory',
      chunkUrlResource: 'modification-histories',
    },
    {
      resource: 'appointmentsNotes',
      chunkUrlResource: 'appointments',
    },
    {
      resource: 'appointmentsAbsencesConflicts',
      chunkUrlResource: 'appointments',
    },
    {
      resource: 'availabilities',
    },
    {
      resource: 'availablePractitioners',
      chunkUrlResource: 'video-sessions/available-practitioners',
    },
    {
      resource: 'contacts',
      chunkResource: 'support',
    },
    {
      resource: 'consultationReasonsComplexForm',
      chunkUrlResource: 'consultation-reasons',
    },
    {
      resource: 'consultationReasonsComplexFormRoot',
      chunkUrlResource: 'consultation-reasons',
    },
    {
      resource: 'centers',
    },
    {
      resource: 'centersChat',
      chunkUrlResource: 'users',
    },
    {
      resource: 'centersParent',
      chunkUrlResource: 'centers',
    },
    {
      resource: 'consultationTypes',
      chunkUrlResource: 'consultation-reasons/consultation-types',
    },
    {
      resource: 'complexFormData',
      chunkUrlResource: 'appointments/complex-form',
    },
    {
      resource: 'documents',
    },
    {
      resource: 'documentsHistory',
      chunkUrlResource: 'documents',
      chunkResource: 'history',
    },
    {
      resource: 'expertises',
    },
    {
      resource: 'feedbackTLC',
      chunkUrlResource: 'feedback-tlc',
    },
    {
      resource: 'notificationSettings',
      chunkUrlResource: 'notification-settings',
    },
    {
      resource: 'nextAvailabilities',
      chunkUrlResource: 'availability-closests',
    },
    {
      resource: 'leaveCenters',
      chunkUrlResource: 'centers',
    },
    {
      resource: 'leaveCentersRoot',
      chunkUrlResource: 'centers',
    },
    {
      resource: 'logout',
      scope: 'auth',
    },
    {
      resource: 'pendingAppointments',
    },
    {
      resource: 'passwordChanges',
      chunkUrlResource: 'password-changes',
      scope: 'auth',
    },
    {
      resource: 'passwordRequests',
      chunkUrlResource: 'password-requests',
      scope: 'auth',
    },
    {
      resource: 'passwordReset',
      chunkUrlResource: 'password-resets',
      scope: 'auth',
    },
    {
      resource: 'passwordResetToken',
      chunkUrlResource: 'token',
      scope: 'auth',
    },
    {
      resource: 'patients',
    },
    {
      resource: 'payments',
    },
    {
      resource: 'posologies',
      chunkUrlResource: 'posologie/produit/up',
      scope: 'bcb',
    },
    {
      resource: 'practitionersComplexForm',
      chunkUrlResource: 'practitioners',
    },
    {
      resource: 'practitionersComplexFormRoot',
      chunkUrlResource: 'practitioners',
    },
    {
      resource: 'practitionersDefault',
      chunkUrlResource: 'practitioners',
    },
    {
      resource: 'profilePictures',
      chunkUrlResource: 'profile-pictures',
    },
    {
      resource: 'profiles',
    },
    {
      resource: 'profilesParent',
      chunkUrlResource: 'profiles',
    },
    {
      resource: 'resourceLabels',
      chunkUrlResource: 'resource-labels',
    },
    {
      resource: 'search',
    },
    {
      resource: 'shareDocuments',
      chunkUrlResource: 'share-documents',
    },
    {
      resource: 'specialitiesComplexForm',
      chunkUrlResource: 'specialities',
    },
    {
      resource: 'specialitiesComplexFormRoot',
      chunkUrlResource: 'specialities',
    },
    {
      resource: 'statistics',
      chunkUrlResource: 'statistics',
    },
    // {
    //   resource: 'users',
    // },
    {
      resource: 'substitutePractitioners',
      chunkUrlResource: 'users',
    },
    {
      resource: 'videoSessions',
      chunkUrlResource: 'video-sessions',
    },
    {
      resource: 'weekTemplateCycles',
      chunkUrlResource: 'week-template-cycles',
    },
    {
      resource: 'weekTemplateCyclesConflicts',
      chunkUrlResource: 'week-template-cycles',
    },
    {
      resource: 'creatableConsultationReasons',
      chunkUrlResource: 'creatable-consultation-reasons',
    },
    {
      resource: 'creatableConsultationReasonsGroups',
      chunkUrlResource: 'creatable-consultation-reason-groups',
    },
    {
      resource: 'notificationSettingsTokens',
      chunkUrlResource: 'notification-settings-tokens',
    },
    {
      resource: 'omnidocDocuments',
    },
    {
      resource: 'onfido',
    },
    {
      resource: 'userPractitioners',
      chunkUrlResource: 'user-practitioners',
    },
    {
      resource: 'specialities',
      scope: 'pro-public',
    },
    {
      resource: 'teleconsultationReminders',
      chunkUrlResource: 'teleconsultation-reminders',
    },
    {
      resource: 'clearAccessRequest',
      chunkUrlResource: 'users',
    },
    {
      resource: 'verifyIdentity',
      chunkUrlResource: 'verify-identity',
      scope: 'pro-public',
    },
    {
      resource: 'existingEmail',
      chunkUrlResource: 'existing-email',
      scope: 'pro-public',
    },
    {
      resource: 'chatOnBoarding',
      chunkUrlResource: 'chat-on-boarding',
      scope: 'pro-public',
    },
    {
      resource: 'confirmCode',
      chunkUrlResource: 'registration/confirm-code',
    },
    {
      resource: 'resendCode',
      chunkUrlResource: 'registration/resend-confirmation',
    },
    {
      resource: 'teleconsultation',
      chunkUrlResource: 'tlc',
    },
    {
      resource: 'recurringAvailabilities',
      chunkUrlResource: 'availabilities-recurring',
    },
    {
      resource: 'rightsCenter',
      chunkUrlResource: 'centers',
    },
    {
      resource: 'assistedTLC',
      chunkUrlResource: 'connection/assisted-patient',
    },
    {
      resource: 'patientReferral',
      chunkUrlResource: 'connection/referral',
    },
    {
      resource: 'conventionedActs',
      chunkUrlResource: 'conventioned-acts',
    },
    {
      resource: 'medicalContexts',
      chunkUrlResource: 'patients/medical-contexts',
    },
    {
      resource: 'secretaryInstructions',
      chunkUrlResource: 'secretary-instructions',
    },
    {
      resource: 'secretaryInstructionCategories',
      chunkUrlResource: 'secretary-instruction-categories',
    },
    // Chat related actions
    // TODO: to delete when chat is totally removed
    { resource: 'chatRoomDocuments' },
    {
      resource: 'chatRooms',
      chunkUrlResource: 'chat-rooms',
    },
    {
      resource: 'chatUsersTyping',
    },
    {
      resource: 'chatDrafts',
    },
    {
      resource: 'trackingProfile',
      chunkUrlResource: 'tracking-profile',
    },
    {
      resource: 'unreadMessages',
      chunkUrlResource: 'chat-messages',
      chunkResource: 'unread-messages',
    },
    {
      resource: 'searchInvitation',
      chunkUrlResource: 'chat-on-boarding/search',
      scope: 'pro-public',
    },
  ].map(template => ({
    ...template,
    disableConcurrency: {
      isGetListDisableConcurrency: true,
      isGetOneDisableConcurrency: true,
    },
  })) as ActionTemplateType[],
);

const genericsActionsV2: any = {};
actionCrudV2.forEach(action => {
  genericsActionsV2[`${action.resource}Actions`] = action;
});

const actions: CrudActions & ChatActions = genericsActionsV2;

export type ReduxActions = CrudActions & ChatActions;

export const {
  invitationSuggestionsActions,
  actionsHistoryActions,
  agendaSettingsCentersActions,
  appointmentsAbsencesConflictsActions,
  appointmentsHistoryActions,
  appointmentsNotesActions,
  autoOnBoardingActions,
  availabilitiesActions,
  availablePractitionersActions,
  bcbDosagesActions,
  buildActions,
  centersChatActions,
  centersParentActions,
  clearAccessRequestActions,
  complexFormDataActions,
  consultationReasonsComplexFormActions,
  consultationReasonsComplexFormRootActions,
  consultationTypesActions,
  chatRoomDocumentsActions,
  contactsActions,
  creatableConsultationReasonsActions,
  creatableConsultationReasonsGroupsActions,
  documentsActions,
  documentsHistoryActions,
  expertisesActions,
  feedbackTLCActions,
  leaveCentersActions,
  leaveCentersRootActions,
  logoutActions,
  nextAvailabilitiesActions,
  notificationSettingsActions,
  notificationSettingsTokensActions,
  omnidocDocumentsActions,
  onfidoActions,
  passwordChangesActions,
  passwordRequestsActions,
  passwordResetActions,
  passwordResetTokenActions,
  paymentsActions,
  pendingAppointmentsActions,
  patientsActions,
  practitionersComplexFormActions,
  practitionersComplexFormRootActions,
  practitionersDefaultActions,
  profilePicturesActions,
  profilesActions,
  profilesParentActions,
  resourceLabelsActions,
  substitutePractitionersActions,
  searchActions,
  shareDocumentsActions,
  statisticsActions,
  specialitiesActions,
  specialitiesComplexFormActions,
  specialitiesComplexFormRootActions,
  teleconsultationRemindersActions,
  userPractitionersActions,
  weekTemplateCyclesActions,
  weekTemplateCyclesConflictsActions,
  posologiesActions,
  chatOnBoardingActions,
  verifyIdentityActions,
  existingEmailActions,
  confirmCodeActions,
  resendCodeActions,
  recurringAvailabilitiesActions,
  rightsCenterActions,
  assistedTLCActions,
  patientReferralActions,
  conventionedActsActions,
  medicalContextsActions,
  secretaryInstructionsActions,
  secretaryInstructionCategoriesActions,
  // Chat actions
  // TODO: to delete when chat is totally removed
  chatDraftsActions,
  chatRoomsActions,
  chatUsersTypingActions,
  trackingProfileActions,
  unreadMessagesActions,
  searchInvitationActions,
} = actions;

export {
  acknowledgeDocumentsActions,
  appointmentsActions,
  appointmentsConflictsActions,
  appointmentsTlcActions,
  associationCodeActions,
  authenticationActions,
  calendarActions,
  centersActions,
  chatUtilsActions,
  complexFormActions,
  confirmLogoutActions,
  consultationReasonsActions,
  consultationReasonsParentActions,
  dialogActions,
  exportStatisticsDocumentActions,
  loaderMaiiaActions,
  loadingActions,
  mergePatientsActions,
  notificationCentersActions,
  offsetActions,
  phoneCheckActions,
  practitionersActions,
  practitionersTimeSlotsActions,
  prescriptionActions,
  resourcesActions,
  resourcesCenterActions,
  resourcesTimeSlotActions,
  resourcesTimeSlotGroupActions,
  snacksActions,
  teleconsultationActions,
  timeSlotResourcesCalendarActions,
  timeSlotsActions,
  treatmentsActions,
  videoProMeetingActions,
  videoSessionsActions,
  websocketActions,
};
