export type TrackUserEvent = (
  action: GAActionsType,
  status?: GAActionStatus,
  label?: GALabelsType,
  noLabelCheck?: boolean,
  value?: number,
) => void;

export enum GACategories {
  Header = 'Header',
  PatientList = 'PatientList',
  SimpleFormBooking = 'SimpleFormBooking',
  ComplexFormBooking = 'ComplexFormBooking',
  Note = 'Note',
  SideBarAgenda = 'SideBarAgenda',
  Chat = 'Chat',
  TeleconsultationSession = 'TeleconsultationSession',
  Transactions = 'TeleconsultationHistory',
  TeleconsultationSetting = 'TeleconsultationSetting',
  AgendaView = 'AgendaView',
  PatientFile = 'PatientFile',
  AgendaSettingConsultationReason = 'AgendaSettingConsultationReason',
  AgendaSettingPractitionerProfile = 'AgendaSettingPractitionerProfile',
  AgendaSettingResources = 'AgendaSettingResources',
  AgendaSettingInstructions = 'AgendaSettingInstructions',
  AgendaSettingTerminalInstructions = 'AgendaSettingTerminalInstructions',
  AgendaSettingNotifications = 'AgendaSettingNotifications',
  AgendaSettingSecretaryInstruction = 'AgendaSettingSecretaryInstruction',
  AppointmentsHistory = 'AppointmentsHistory',
  Authentication = 'Authentication',
  Ehr = 'Ehr',
  WaitingList = 'WaitingList',
  Upsell = 'Upsell',
  Print = 'Print',
  Omnidoc = 'Omnidoc',
  AppointmentDetailsDialog = 'AppointmentDetailsDialog',
}

export enum GAUpsellActions {
  ClickUpsellAgendaPromotionalPage = 'ClickUpsellAgendaPromotionalPage',
  ClickUpsellOpenContactRequest = 'ClickUpsellOpenContactRequest',
  ClickUpsellValidationContactRequest = 'ClickUpsellValidationContactRequest',
  ClickUpsellAgendaDocumentInstructions = 'ClickUpsellAgendaDocumentInstructions',
  ClickUpsellAgendaDelay = 'ClickUpsellAgendaDelay',
  ClickUpsellAgendaConsultationReasonOnline = 'ClickUpsellAgendaConsultationReasonOnline',
  ClickUpsellAgendaConsultationReasonOnlineOnlyPro = 'ClickUpsellAgendaConsultationReasonOnlineOnlyPro',
  ClickUpsellAgendaNotificationsSettings = 'ClickUpsellAgendaNotificationsSettings',
  ClickUpsellAgendaProfilePratOnline = 'ClickUpsellAgendaProfilePratOnline',
  ClickUpsellAgendaSettingsInstructions = 'ClickUpsellAgendaSettingsInstructions',
  ClickUpsellAgendaGeneratePrescriptionBcb = 'ClickUpsellAgendaGeneratePrescriptionBcb',
  ClickUpsellAgendaGeneratePrescriptionComplementary = 'ClickUpsellAgendaGeneratePrescriptionComplementary',
  ClickUpsellAgendaGenerateReport = 'ClickUpsellAgendaGenerateReport',
  ClickUpsellAgendaWaitingList = 'ClickUpsellAgendaWaitingList',
  ClickUpsellAgendaGenerateMedicalCertificate = 'ClickUpsellAgendaGenerateMedicalCertificate',
  ClickUpsellAgendaDuplicateAppointments = 'ClickUpsellAgendaDuplicateAppointments',
  ClickUpsellAgendaOmnidocTeleexpertise = 'ClickUpsellAgendaOmnidocTeleexpertise',
  ClickUpsellAgendaTlc = 'ClickUpsellAgendaTlc',
}

export enum GAHeaderActions {
  SelectSearch = 'SelectSearch',
  ClickSearchActionIcon = 'ClickSearchActionIcon',
  ClickNotificationCenter = 'ClickNotificationCenter',
  ClickNotification = 'ClickNotification',
  ClickActionHistory = 'ClickActionHistory',
  ClickDocumentHistory = 'ClickDocumentHistory',
  ClickChatIcon = 'ClickChatIcon',
  ClickPatientList = 'ClickPatientList',
  ClickTransactions = 'ClickTeleconsultationHistory',
  ClickAgendaSetting = 'ClickAgendaSetting',
  ClickAdministration = 'ClickAdministration',
  ClickStatistic = 'ClickStatistic',
  ClickHelp = 'ClickHelp',
  ClickMyAccount = 'ClickMyAccount',
  ClickDisconnect = 'ClickDisconnect',
  OpenComplexFormResearchedPatient = 'OpenComplexFormResearchedPatient',
  OpenComplexFormResearchedPractitioner = 'OpenComplexFormResearchedPractitioner',
}

export enum GAHeaderLabels {
  PatientAppointment = 'PatientAppointment',
  CenterAppointment = 'CenterAppointment',
  PractitionerAppointment = 'PractitionerAppointment',
  DocumentNotification = 'DOCUMENT',
  NewsNotification = 'NEWS',
  AppointmentNotification = 'APPOINTMENT',
  BillingNotification = 'BILLING REMINDER',
  GoPatientFile = 'GoPatientFile',
  GoCenterAgenda = 'GoCenterAgenda',
  GoPractitionerAgenda = 'GoPractitionerAgenda',
}

export enum GAPatientListActions {
  ClickSearch = 'ClickSearch',
  ClickFusion = 'ClickFusion',
}

export enum GAPatientListLabels {
  Name = 'Name',
  PhoneNumber = 'PhoneNumber',
  Email = 'Email',
  NSS = 'NSS',
  BirthDate = 'BirthDate',
  UNKNOWN = 'UNKNOWN',
  OK = 'OK',
  ERROR = 'ERROR',
}

export enum GANoteActions {
  ClickPlusButton = 'ClickPlusButton',
  SaveNote = 'SaveNote',
}

export enum GASimpleFormActions {
  OpenSimpleForm = 'OpenSimpleForm',
  SelectConsultationReason = 'SelectConsultationReason',
  SelectConsultationType = 'SelectConsultationType',
  ClickAddFirstConsultationReason = 'ClickAddFirstConsultationReason',
  SelectPatient = 'SelectPatient',
  SelectSuggestedPatient = 'SelectSuggestedPatient',
  SelectResearchedPatient = 'SelectResearchedPatient',
  CreatePatient = 'CreatePatient',
  ClickComment = 'ClickComment',
  ClickValidate = 'ClickValidate',
  ClickComplexForm = 'ClickComplexForm',
  OpenComplexFormMoreOptions = 'OpenComplexFormMoreOptions',
  OpenComplexFormModification = 'OpenComplexFormModification',
  OpenComplexFormReschedule = 'OpenComplexFormReschedule',
  ClickOther = 'ClickOther',
  ClickOtherAppointment = 'ClickOtherAppointment',
  SelectOtherAppointmentRecurrence = 'SelectOtherAppointmentRecurrence',
  SelectAbsenceRecurrence = 'SelectAbsenceRecurrence',
  SaveOtherAppointment = 'SaveOtherAppointment',
  SaveAbsence = 'SaveAbsence',
  ClickAbsence = 'ClickAbsence',
  SelectOtherAppointmentColor = 'SelectOtherAppointmentColor',
}

export enum GASimpleFormLabels {
  PHYSICAL = 'PHYSICAL',
  TELECONSULTATION = 'TELECONSULTATION',
  HOMEVISIT = 'HOMEVISIT',
  NO_RECURRENCE = 'NO_RECURRENCE',
  EVERY_1_DAY = 'EVERY_1_DAY',
  EVERY_1_WEEK = 'EVERY_1_WEEK',
  EVERY_2_WEEK = 'EVERY_2_WEEK',
  EVERY_3_WEEK = 'EVERY_3_WEEK',
  EVERY_4_WEEK = 'EVERY_4_WEEK',
}

export enum GAComplexFormActions {
  OpenComplexForm = 'OpenComplexForm',
  CloseComplexForm = 'CloseComplexForm',
  SelectCenter = 'SelectCenter',
  SelectSpecialty = 'SelectSpecialty',
  SelectConsultationReason = 'SelectConsultationReason',
  SelectConsultationType = 'SelectConsultationType',
  ClickAddFirstConsultationReason = 'ClickAddFirstConsultationReason',
  SelectNoPreferencePractitioner = 'SelectNoPreferencePractitioner',
  SelectResearchRelatedPractitioner = 'SelectResearchRelatedPractitioner',
  SelectOtherPractitioner = 'SelectOtherPractitioner',
  SelectSuggestedPatient = 'SelectSuggestedPatient',
  SelectResearchedPatient = 'SelectResearchedPatient',
  CreatePatient = 'CreatePatient',
  SelectDate = 'SelectDate',
  SelectHour = 'SelectHour',
  SelectDuration = 'SelectDuration',
  SelectFilter = 'SelectFilter',
  ClickMultipleAppointment = 'ClickMultipleAppointment',
  AddAppointmentCommentary = 'AddAppointmentCommentary',
  ClickCancel = 'ClickCancel',
  ClickValidate = 'ClickValidate',
  ClickCallTLS = 'ClickCallTLS',
  EditPatient = 'EditPatient',
  ClickBlockPatient = 'ClickBlockPatient',
  ClickUnBlockPatient = 'ClickUnBlockPatient',
  ClickDeletePatient = 'ClickDeletePatient',
  ClickPatientTab = 'ClickPatientTab',
  ClickAppointmentTab = 'ClickAppointmentTab',
  ClickDocumentTab = 'ClickDocumentTab',
  CloseDocumentWindows = 'CloseDocumentWindows',
  ClickDocumentSharedByPractitioner = 'ClickDocumentSharedByPractitioner',
  ClickDocumentSharedByPatient = 'ClickDocumentSharedByPatient',
  SelectDocumentCategory = 'SelectDocumentCategory',
  ShareAutomatically = 'ShareAutomatically',
  ClickSendDocument = 'ClickSendDocument',
  ClickCancelDocument = 'ClickCancelDocument',
  SelectAvailability = 'SelectAvailability',
  UnSelectAvailability = 'UnSelectAvailability',
  OpenRecurrencyAppointmentsMode = 'OpenRecurrencyAppointmentsMode',
  ConfirmRecurrencyAppointmentsMode = 'ConfirmRecurrencyAppointmentsMode',
  CancelRecurrencyAppointmentsMode = 'CancelRecurrencyAppointmentsMode',
  UnselectedOverbookedTimeslot = 'UnselectedOverbookedTimeslot',
}

export enum GAComplexFormLabels {
  SearchConsultationReason = 'SearchConsultationReason',
  OtherConsultationReason = 'OtherConsultationReason',
  PHYSICAL = 'PHYSICAL',
  TELECONSULTATION = 'TELECONSULTATION',
  HOMEVISIT = 'HOMEVISIT',
  NO_RECURRENCE = 'NO_RECURRENCE',
  Yes = 'Yes',
  No = 'No',
  MultiAvailabilities = 'MultiAvailabilities',
  SingleAvailability = 'SingleAvailability',
  RecurrenciesAvailabilitiesPercentage = 'RecurrenciesAvailabilitiesPercentage',
}

export enum GASideBarAgendaActions {
  MiniCalendarDatePicker = 'MiniCalendarDatePicker',
  ClickPreviousMonth = 'ClickPreviousMonth',
  ClickNextMonth = 'ClickNextMonth',
  ClickCenter = 'ClickCenter',
  ClickMultipleCenterView = 'ClickMultipleCenterView',
  ClickMultiplePractitionerView = 'ClickMultiplePractitionerView',
  SelectCenter = 'SelectCenter',
  ClickSearchUser = 'ClickSearchUser',
  ClickOtherUser = 'ClickOtherUser',
  ClickOwnUser = 'ClickOwnUser',
  ClickSelectAllPractitioners = 'ClickSelectAllPractitioners',
  ClickUnselectAllPractitioners = 'ClickUnselectAllPractitioners',
}

export enum GASideBarAgendaLabels {
  _2 = 2,
  _3 = 3,
  _4 = 4,
  _5 = 5,
  _6 = 6,
}

export enum GAChatActions {
  ClickCenter = 'ClickCenter',
  SelectCenter = 'SelectCenter',
  ThreadHistory = 'ThreadHistory',
  ClickGroupCreation = 'ClickGroupCreation',
  ClickValidateGroupCreation = 'ClickValidateGroupCreation',
  ClickOwnChatRoom = 'ClickOwnChatRoom',
  ClickCreateTask = 'ClickCreateTask',
  ClickDeleteTask = 'ClickDeleteTask',
  ClickValidateTask = 'ClickValidateTask',
  ClickThread = 'ClickThread',
  ClickSharePatientFile = 'ClickSharePatientFile',
  SelectPatientFile = 'SelectPatientFile',
  ClickShareDocument = 'ClickShareDocument',
  CloseDocumentWindows = 'CloseDocumentWindows',
  ClickCancelDocument = 'ClickCancelDocument',
  SelectDocumentCategory = 'SelectDocumentCategory',
  ShareAutomatically = 'ShareAutomatically',
  ClickSendDocument = 'ClickSendDocument',
}
export enum GAChatLabels {
  Yes = 'Yes',
  No = 'No',
}

export enum GATlcSessionActions {
  ClickRefuseDirectTLC = 'ClickRefuseDirectTLC',
  ClickWaitPatientDirectTLC = 'ClickWaitPatientDirectTLC',
  ClickLaunchDirectTLC = 'ClickLaunchDirectTLC',
  ClickBillPatient = 'ClickBillPatient',
  ClickCameraOnOff = 'ClickCameraOnOff',
  ClickMicroOnOff = 'ClickMicroOnOff',
  ClickShareScreen = 'ClickShareScreen',
  ClickHangup = 'ClickHangup',
  ClickMoreOption = 'ClickMoreOption',
  ClickCancelHangup = 'ClickCancelHangup',
  ClickConfirmHangup = 'ClickConfirmHangup',
  ClickPatientTab = 'ClickPatientTab',
  ClickAppointmentTab = 'ClickAppointmentTab',
  ClickDocumentTab = 'ClickDocumentTab',
  CloseDocumentWindows = 'CloseDocumentWindows',
  ClickDocumentSharedByPractitioner = 'ClickDocumentSharedByPractitioner',
  ClickDocumentSharedByPatient = 'ClickDocumentSharedByPatient',
  SelectDocumentCategory = 'SelectDocumentCategory',
  ShareAutomatically = 'ShareAutomatically',
  ClickSendDocument = 'ClickSendDocument',
  ClickCancelDocument = 'ClickCancelDocument',
  ClickAccessPatientFile = 'ClickAccessPatientFile',
  ClickFullThirdPartyPayment = 'ClickFullThirdPartyPayment',
}

export enum GATlcLabels {
  PAYMENT_TYPE_FREE = '_0',
  PAYMENT_TYPE_DEFAULT = 'DefaultPrice',
  PAYMENT_TYPE_OTHER = 'Other',
  PAYMENT_TYPE_LATER = 'After',
  ON = 'On',
  OFF = 'Off',
  Yes = 'Yes',
  No = 'No',
  VIDEO_CHOICE = 'VideoChoice',
  AUDIO_CHOICE = 'MicroChoice',
}

export enum GATransactionActions {
  ClickExport = 'ClickExport',
  ClickBill = 'ClickBill',
  ClickAllPractitioners = 'ClickCenterTeleconsultationHistory',
}

export enum GATransactionLabels {
  lessThan1Day = '<1',
  lessThan2Days = '<2',
  lessThan3Days = '<3',
  lessThan4Days = '<4',
  lessThan5Days = '<5',
  lessThan6Days = '<6',
  lessThan7Days = '<7',
}

export enum GATlcSettingActions {
  OpenTeleconsultationSetting = 'OpenTeleconsultationSetting',
  ClickTeleconsultationOnDemand = 'ClickTeleconsultationOnDemand',
  SelectKnownPatient = 'SelectKnownPatient',
  ClickLaunchTeleconsultationOnDemand = 'ClickLaunchTeleconsultationOnDemand',
  ClickAccessWaitingRoom = 'ClickAccessWaitingRoom',
  ClickTeleconsultationSetting = 'ClickTeleconsultationSetting',
  Network = 'Network',
  ClickTestAudio = 'ClickTestAudio',
  ClickTestCamera = 'ClickTestCamera',
  SelectDefaultPriceTeleconsultation = 'SelectDefaultPriceTeleconsultation',
  ClickCancelTeleconsultationSetting = 'ClickCancelTeleconsultationSetting',
  ClickValidateTeleconsultationSetting = 'ClickValidateTeleconsultationSetting',
}

export enum GAOmnidocActions {
  TLEOmnidoc = 'TLEOmnidoc',
  TLEsentOmnidoc = 'TLEsentOmnidoc',
}

export enum GATlcSettingLabels {
  SelectKnownPatient = 'KnownPatient',
  SelectAllPatient = 'AllPatient',
  ExcellentConnexion = 'ExcellentConnexion',
  MediumConnexion = 'MediumConnexion',
  BadConnexion = 'BadConnexion',
}

export enum GAAgendaViewActions {
  ClickPreviousWeek = 'ClickPreviousWeek',
  ClickNextWeek = 'ClickNextWeek',
  ClickAgendaSettingView = 'ClickAgendaSettingView',
  SelectAgendaView = 'SelectAgendaView',
  SelectZoom = 'SelectZoom',
  SelectDefaultTimeAppointment = 'SelectDefaultTimeAppointment',
  OpenComplexFormPlus = 'OpenComplexFormPlus',
}

export enum GAAgendaViewLabels {
  DAY = 'DAY',
  WEEK = 'WEEK',
  PLANNING = 'PLANNING',
  _75Zoom = '75%',
  _100Zoom = '100%',
  _130Zoom = '130%',
  _250Zoom = '250%',
  _350Zoom = '350%',
  _5MinutesAppointment = '5min',
  _10MinutesAppointment = '10min',
  _15MinutesAppointment = '15min',
  _20MinutesAppointment = '20min',
  _30MinutesAppointment = '30min',
  _40MinutesAppointment = '40min',
  _60MinutesAppointment = '60min',
}

export enum GAPatientFileActions {
  ClickCallTLS = 'ClickCallTLS',
  EditPatient = 'EditPatient',
  ClickBlockPatient = 'ClickBlockPatient',
  ClickUnBlockPatient = 'ClickUnBlockPatient',
  ClickDeletePatient = 'ClickDeletePatient',
  ClickPatientTab = 'ClickPatientTab',
  ClickAppointmentTab = 'ClickAppointmentTab',
  ClickDocumentTab = 'ClickDocumentTab',
  ClickEhrTab = 'ClickEhrTab',
  CloseDocumentWindows = 'CloseDocumentWindows',
  ClickDocumentSharedByPractitioner = 'ClickDocumentSharedByPractitioner',
  ClickDocumentSharedByPatient = 'ClickDocumentSharedByPatient',
  SelectDocumentCategory = 'SelectDocumentCategory',
  ShareAutomatically = 'ShareAutomatically',
  ClickSendDocument = 'ClickSendDocument',
  ClickCancelDocument = 'ClickCancelDocument',
  ClickPatientReferral = 'ClickPatientReferral',
  ClickDeuxiemeavis = 'ClickDeuxiemeavis',
  ClickDeuxiemeavisConfirm = 'ClickDeuxiemeavisConfirm',
}

export enum GAAgendaSettingConsultationReasonActions {
  ClickCreateSimpleConsultationReason = 'ClickCreateSimpleConsultationReason',
  ClickCreateMultipleConsultationReason = 'ClickCreateMultipleConsultationReason',
  SwitchSimpleConsultationReason = 'SwitchSimpleConsultationReason',
  SwitchConsultationReasonGroup = 'SwitchConsultationReasonGroup',
  SelectDurationConsultationReason = 'SelectDurationConsultationReason',
  ClickKnowPatientLimitConsultationReason = 'ClickKnowPatientLimitConsultationReason',
  ClickNotificationConsultationReason = 'ClickNotificationConsultationReason',
  SelectConsultationReasonType = 'SelectConsultationReasonType',
  SelectVisibleOnlineConsultationReason = 'SelectVisibleOnlineConsultationReason',
  SelectResource = 'SelectResource',
  OnlineOpeningHour = 'OnlineOpeningHour',
}

export enum GAAgendaSettingConsultationReasonLabels {
  KnownPatient = 'KnownPatient',
  AllPatient = 'AllPatient',
  DisabledNotification = 'DisabledNotification',
  Notification = 'Notification',
  Physical = 'Physical',
  Video = 'Video',
  PhysicalAndVideo = 'PhysicalAndVideo',
  Visible = 'Visible',
  Invisible = 'Invisible',
  OnlyPro = 'OnlyPro',
}

export enum GAAgendaSettingPractitionerProfileActions {
  SelectExpertise = 'SelectExpertise',
  SelectConvention = 'SelectConvention',
  ClickAddAct = 'ClickAddAct',
  SelectRefund = 'SelectRefund',
  SelectPaymentChoice = 'SelectPaymentChoice',
  SelectOpenAppointmentDuration = 'SelectOpenAppointmentDuration',
  SelectInterdictionAppointmentDuration = 'SelectInterdictionAppointmentDuration',
}

export enum GAAgendaSettingPractitionerProfileLabels {
  Tiers = 'Tiers',
  CarteVitale = 'Carte Vitale',
  Check = 'Check',
  BankCard = 'BankCard',
  Cash = 'Cash',
  NothingSelected = 'No Options Selected',
}

export enum AnalyticsResourcesActions {
  ClickAddResource = 'ClickAddResource',
  CloseAddResource = 'CloseAddResource',
  SelectResource = 'SelectResource',
  ClickConfirmationAddResource = 'ClickConfirmationAddResource',
  SelectCapacityNumberResource = 'SelectCapacityNumberResource',
  ClickBlockingResource = 'ClickBlockingResource',
  SelectUnavailabilityDuration = 'SelectUnavailabilityDuration',
}

export enum AnalyticsResourcesLabels {
  Blocking = 'Blocking',
  UnBlocking = 'UnBlocking',
}

export enum AnalyticsInstructionsActions {
  SetDefaultCenterInstruction = 'SetDefaultCenterInstruction',
  SetDefaultPractitionerInstruction = 'SetDefaultPractitionerInstruction',
  SetCenterConsultationReasonInstruction = 'SetCenterConsultationReasonInstruction',
  SetPractitionerConsultationReasonInstruction = 'SetPractitionerConsultationReasonInstruction',
  DragAndDropSecretaryInstruction = 'DragAndDropSecretaryInstruction',
  InsertLink = 'InsertLink',
  AttachFiles = 'AttachFiles',
}

export enum AnalyticsEhrActions {
  ImportEhrPatientData = 'ImportEhrPatientData',
  SavedEhrData = 'SavedEhrData',
  DisplayEhrGraph = 'DisplayEhrGraph',
}

export enum GATerminalInstructionSettingActions {
  SetDefaultCenterInstruction = 'SetDefaultCenterInstruction',
  SetDefaultPractitionerInstruction = 'SetDefaultPractitionerInstruction',
  SetCenterConsultationReasonInstruction = 'SetCenterConsultationReasonInstruction',
  SetPractitionerConsultationReasonInstruction = 'SetPractitionerConsultationReasonInstruction',
}

export enum GANotificationSettingActions {
  SelectCenterSMS = 'SelectCenterSMS',
  SelectPersonalizedSMS = 'SelectPersonalizedSMS',
}

export enum AnalyticsSecretaryInstructionsActions {
  SetDefaultPractitionerSecretaryInstruction = 'SetDefaultPractitionerSecretaryInstruction',
}

export enum GAAppointmentsHistoryActions {
  ScrollToUpcomingAppointments = 'ScrollToUpcomingAppointments',
  ScrollToNextAppointment = 'ScrollToNextAppointment',
  SeeMorePastAppointments = 'SeeMorePastAppointments',
  ToggleAppointmentTreatmentView = 'ToggleAppointmentTreatmentView',
  AddAppointmentsToTreatment = 'AddAppointmentsToTreatment',
  RemoveAppointmentsFromTreatment = 'RemoveAppointmentsFromTreatment',
  OpenComplexFormPlus = 'OpenComplexFormPlus',
}

export enum GAMedicalContextsActions {
  ImportPatienInformation = 'ImportPatienInformation',
}

export enum GAAuthenticationActions {
  ClickRememberDevice = 'ClickRememberDevice',
  StartMultiFactorAuthentication = 'StartMultiFactorAuthentication',
  SuccessMultiFactorAuthentication = 'SuccessMultiFactorAuthentication',
  FailMultiFactorAuthentication = 'FailMultiFactorAuthentication',
  SuccessWithoutMultiFactorAuthentication = 'SuccessWithoutMultiFactorAuthentication',
}

export enum GAAuthenticationLabels {
  MFA_NOT_FOUND = 'MFA_NOT_FOUND',
  MFA_EXPIRED = 'MFA_EXPIRED',
  MFA_DIFFERENT_DEVICE = 'MFA_DIFFERENT_DEVICE',
  MFA_TOO_MANY_REQUESTS = 'MFA_TOO_MANY_REQUESTS',
  MFA_TOO_MANY_ATTEMPTS = 'MFA_TOO_MANY_ATTEMPTS',
  AUTH_ERROR_018 = 'AUTH_ERROR_018',
}

export enum GAEhrDataLabels {
  OBSERVATIONS = 'OBSERVATIONS',
  CONDITIONS = 'CONDITIONS',
  ALLERGY = 'ALLERGY',
  MEDICATION = 'MEDICATION',
}

export enum GAWaitingListActions {
  ClickWaitingListSwitch = 'ClickWaitingListSwitch',
  ClickWaitingListTab = 'ClickWaitingListTab',
  ClickSearch = 'ClickSearch',
  SelectAll = 'SelectAll',
  SelectByPractitioner = 'SelectByPractitioner',
  SelectByPatient = 'SelectByPatient',
  ClickModifyAppointment = 'ClickModifyAppointment',
  ClickDeleteFromWaitingList = 'ClickDeleteFromWaitingList',
  ClickDeleteFromWaitingListConfirm = 'ClickDeleteFromWaitingListConfirm',
  ClickDeleteFromWaitingListCancel = 'ClickDeleteFromWaitingListCancel',
  ClickDeleteFromWaitingListWithAppointmentCancellation = 'ClickDeleteFromWaitingListWithAppointmentCancellation',
}

export enum GACopyPasteAgendaActions {
  ClickOneAppointmentDuplication = 'ClickOneAppointmentDuplication',
  ClickMultipleDuplicationIcon = 'ClickMultipleDuplicationIcon',
  ClickCopyPasteAgendaOneOrMore = 'ClickCopyPasteAgendaOneOrMore',
  ClickCopyPasteAgendaAllDay = 'ClickCopyPasteAgendaAllDay',
  ClickCancelCopyModeAgenda = 'ClickCancelCopyModeAgenda',
  ClickCancelPasteModeAgenda = 'ClickCancelPasteModeAgenda',
  ClickCopyAppointment = 'ClickCopyAppointment',
  ClickPasteAppointment = 'ClickPasteAppointment',
}

export enum GAPrintActions {
  ClickPrintFromAppointmentInfo = 'ClickPrintFromAppointmentInfo',
  ClickPrintPortraitFromAppointmentInfo = 'ClickPrintPortraitFromAppointmentInfo',
  ClickPrintLandscapeFromAppointmentInfo = 'ClickPrintLandscapeFromAppointmentInfo',
  ClickPrintFromAppointmentsHistoryList = 'ClickPrintFromAppointmentsHistoryList',
  ClickPrintPortraitFromAppointmentsHistoryList = 'ClickPrintPortraitFromAppointmentsHistoryList',
  ClickPrintLandscapeFromAppointmentsHistoryList = 'ClickPrintLandscapeFromAppointmentsHistoryList',
  ClickPrintFromAgenda = 'ClickPrintFromAgenda',
  ClickPrintPortraitFromAgenda = 'ClickPrintPortraitFromAgenda',
  ClickPrintLandscapeFromAgenda = 'ClickPrintLandscapeFromAgenda',
  SelectStandardImpressionTypeFromAgenda = 'SelectStandardImpressionTypeFromAgenda',
  SelectAtHomeImpressionTypeFromAgenda = 'SelectAtHomeImpressionTypeFromAgenda',
}

export const GAActions = {
  ...GAHeaderActions,
  ...GAPatientListActions,
  ...GASimpleFormActions,
  ...GANoteActions,
  ...GAComplexFormActions,
  ...GASideBarAgendaActions,
  ...GAChatActions,
  ...GATlcSessionActions,
  ...GATransactionActions,
  ...GATlcSettingActions,
  ...GAAgendaViewActions,
  ...GAPatientFileActions,
  ...GAAgendaSettingConsultationReasonActions,
  ...GAAgendaSettingPractitionerProfileActions,
  ...AnalyticsResourcesActions,
  ...AnalyticsInstructionsActions,
  ...GATerminalInstructionSettingActions,
  ...GANotificationSettingActions,
  ...AnalyticsSecretaryInstructionsActions,
  ...GAAppointmentsHistoryActions,
  ...GAMedicalContextsActions,
  ...GAAuthenticationActions,
  ...AnalyticsEhrActions,
  ...GAWaitingListActions,
  ...GAUpsellActions,
  ...GAPrintActions,
  ...GAOmnidocActions,
  ...GACopyPasteAgendaActions,
};

export type GAActionsType =
  | GAHeaderActions
  | GAPatientListActions
  | GASimpleFormActions
  | GANoteActions
  | GAComplexFormActions
  | GASideBarAgendaActions
  | GAChatActions
  | GATlcSessionActions
  | GATransactionActions
  | GATlcSettingActions
  | GAAgendaViewActions
  | GAPatientFileActions
  | GAAgendaSettingConsultationReasonActions
  | GAAgendaSettingPractitionerProfileActions
  | AnalyticsResourcesActions
  | AnalyticsInstructionsActions
  | GATerminalInstructionSettingActions
  | GANotificationSettingActions
  | AnalyticsSecretaryInstructionsActions
  | GAAppointmentsHistoryActions
  | GAMedicalContextsActions
  | GAAuthenticationActions
  | AnalyticsEhrActions
  | GAWaitingListActions
  | GAUpsellActions
  | GAPrintActions
  | GAOmnidocActions
  | GACopyPasteAgendaActions;

export const GALabels = {
  ...GAHeaderLabels,
  ...GAPatientListLabels,
  ...GASimpleFormLabels,
  ...GAComplexFormLabels,
  ...GASideBarAgendaLabels,
  ...GAChatLabels,
  ...GATlcLabels,
  ...GATransactionLabels,
  ...GATlcSettingLabels,
  ...GAAgendaViewLabels,
  ...GAAgendaSettingConsultationReasonLabels,
  ...GAAgendaSettingPractitionerProfileLabels,
  ...AnalyticsResourcesLabels,
  ...GAAuthenticationLabels,
  ...GAEhrDataLabels,
};

export type GALabelsType =
  | GAHeaderLabels
  | GAPatientListLabels
  | GASimpleFormLabels
  | GAComplexFormLabels
  | GASideBarAgendaLabels
  | GAChatLabels
  | GATlcLabels
  | GATlcSettingLabels
  | GATransactionLabels
  | GAAgendaViewLabels
  | GAAgendaSettingConsultationReasonLabels
  | GAAgendaSettingPractitionerProfileLabels
  | AnalyticsResourcesLabels
  | GAAuthenticationLabels
  | GAEhrDataLabels;

export enum GAActionStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type GAEventCategoriesType = {
  name: GACategories;
  actions: {
    // forced to add fallback to string because of enum spreading
    // ex: only one key for `ClickPatientTab` but multiple value would like to point to it
    name: GAActionsType;
    labels?: GALabelsType[];
    status?: GAActionStatus;
  }[];
}[];
