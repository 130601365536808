/* eslint-disable import/no-cycle */
import { useMemo, useState } from 'react';
import { createSelector } from 'reselect';

import { AgendaInterface } from '../../components/contexts/AgendaContext';
import { AGENDA } from '../actions/calendar';
import { MAX_DAY_MULTI_CALENDAR_DISPLAYED } from '../constants';
import { getNbDisplayedDays } from '../utils';
import { useDisplayDateRange, useGetAgendasDisplayTimes } from './dateFormat';
import {
  useCalendarView,
  useDisplayedAgendasKeys,
  useTimeSlotResourcesKeys,
} from './query';

import { DuplicationInfo } from '@/components/contexts/CalendarContext';
import { useSelector } from '@/src/hooks/redux';
import { agendaSettingsSelectors } from '@/src/selector';

export const displayedAgendasSelector = (agendaKeys: string[]) =>
  createSelector(agendaSettingsSelectors.getItems, items =>
    items
      .filter(({ centerId, practitionerId }) =>
        agendaKeys.includes(`${practitionerId}-${centerId}`),
      )
      .slice(0, MAX_DAY_MULTI_CALENDAR_DISPLAYED)
      .sort((a, b) => {
        const pratA = `${a.practitioner?.lastName} ${a.practitioner?.firstName}`;
        const pratB = `${b.practitioner?.lastName} ${b.practitioner?.firstName}`;

        return pratA.localeCompare(pratB!) || 0;
      })
      .map(
        (
          { centerId, practitionerId },
          indexAgenda: number,
        ): AgendaInterface => {
          const agenda = {
            centerId,
            practitionerId,
            indexAgenda,
          };
          return agenda as AgendaInterface;
        },
      ),
  );

// eslint-disable-next-line import/prefer-default-export
export const useCalendarContextValue = () => {
  const agendaKeys = useDisplayedAgendasKeys();
  const timeSlotResources = useTimeSlotResourcesKeys();
  const view = useCalendarView();
  const [duplicationInfo, setDuplicationInfo] = useState<DuplicationInfo>();
  // Display only the agenda of the current practitioner in agenda view.
  const displayedAgendas = useSelector(
    displayedAgendasSelector(
      view === AGENDA && agendaKeys.length > 0 ? [agendaKeys[0]] : agendaKeys,
    ),
  );
  const timeSlotResourcesMode = !!timeSlotResources.length;
  const nbDisplayedCalendars =
    timeSlotResources.length || displayedAgendas.length;
  const nbDisplayedDays = getNbDisplayedDays(nbDisplayedCalendars, view);
  const range = useDisplayDateRange(
    displayedAgendas,
    nbDisplayedDays,
    timeSlotResources.length,
  );
  const getAgendasDisplayTimes = useGetAgendasDisplayTimes();
  const [displayStartTime, displayEndTime] = getAgendasDisplayTimes(
    timeSlotResourcesMode ? null : displayedAgendas,
  );
  const calendarContextValue = useMemo(
    () => ({
      agendas: displayedAgendas,
      timeSlotResources,
      timeSlotResourcesMode,
      nbDisplayedCalendars,
      nbDisplayedDays,
      range,
      displayStartTime,
      displayEndTime,
      duplicationInfo,
      setDuplicationInfo,
    }),
    [
      timeSlotResources,
      displayedAgendas,
      range,
      nbDisplayedDays,
      displayStartTime,
      displayEndTime,
      duplicationInfo,
    ],
  );
  return calendarContextValue;
};
