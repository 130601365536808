import { boolean, mixed, object, string } from 'yup';

import {
  AUTHORIZED_EXTENSIONS,
  AUTHORIZED_MIME_TYPES,
  getFileExtension,
} from '@/src/utils';

export const FILE_SIZE = 20 * 1024 * 1024;
export const SUPPORTED_FORMATS = [
  'image/gif',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/bmp',
  'application/pdf',
  'text/plain',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];
export const SUPPORTED_FORMATS_SIGNATURE = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf',
];

export const DocumentUploadSchema = () =>
  object().shape({
    file: mixed()
      .required('document_upload__file_required')
      .test(
        'fileSize',
        'document_upload__file_size',
        value => value && value.size <= FILE_SIZE,
      )
      .test(
        'fileFormat',
        'document_upload_invalid_type_file',
        value =>
          value &&
          AUTHORIZED_MIME_TYPES.includes(value.type) &&
          AUTHORIZED_EXTENSIONS.includes(getFileExtension(value.name)),
      ),
    category: string().test(
      'required',
      'document_upload__category_required',
      value => !!value && value !== 'empty',
    ),

    practitioner: object()
      .nullable()
      .shape({
        id: string().required('required_field'),
      })
      .required('practitioner_document_required'),
  });

export const DocumentPanelSchema = object().shape({
  title: string().required('document_upload__title_required'),
  category: string().test(
    'required',
    'document_upload__category_required',
    value => !!value && value !== 'empty',
  ),
  isUserConnect: boolean(),
  practitioner: object().when('isUserConnect', {
    is: true,
    then: object().nullable(),
    otherwise: object()
      .nullable()
      .shape({
        id: string().required('required_field'),
      })
      .required('practitioner_document_required'),
  }),
});
