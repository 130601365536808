/* eslint-disable import/no-cycle */
import React from 'react';
import Router from 'next/router';
import find from 'lodash/find';
import get from 'lodash/get';
import last from 'lodash/last';
import moment, { Moment } from 'moment';

import { serialActions } from '@docavenue/core';
import {
  Address,
  AppointmentGetDTO,
  Document,
  PractitionerDestination,
  ProAgendaSettingsDTO,
  TeleconsultationReminder,
  Week,
  WeekTemplateCycle,
} from '@maiia/model/generated/model/api-pro/api-pro';

import {
  appointmentsTlcActions,
  teleconsultationRemindersActions,
  videoSessionsActions,
} from '../../src/actions';
import {
  AGENDA_APOINTMENT_ALLOWED_LENGTH,
  DEFAULT_AGENDA_APPOINTMENT_LENGTH,
  PATIENT,
  STARTED,
} from '../../src/constants';
import Routes from '../../src/constants/routes';
import { capitalize } from '../../src/utils';
import { VideoSessionAggregate } from '../../types/pro.types';

export const generateRandomPassword = () => {
  const chars =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!';
  const charLength = chars.length;
  let result = '';
  for (let i = 0; i < 20; i++) {
    result += chars.charAt(Math.floor(Math.random() * charLength));
  }
  return result;
};

export const addressToString = (address?: Address) => {
  if (!address) return '';
  const { number, street, zipCode, city, fullAddress } = address;
  if (fullAddress) return fullAddress;
  return [number, street, zipCode, city].filter(Boolean).join(' ');
};

export const findWeekTemplateCycleByWeek = (
  date: string | Date | Moment | undefined,
  weekTemplateCycles: WeekTemplateCycle[],
  weeks: (Week | undefined)[],
) => {
  const weekDate = moment(date)
    .startOf('isoWeek')
    .format('YYYY-MM-DD');

  const targetWeek = find(
    weeks,
    week =>
      moment(week?.startDate)
        .startOf('isoWeek')
        .format('YYYY-MM-DD') === weekDate,
  );
  if (!targetWeek) return null;

  const targetWeekTemplateCycle = find(weekTemplateCycles, {
    id: targetWeek.weekTemplateCycleId,
  });
  return targetWeekTemplateCycle;
};

const splitPhoneNumber = (phoneNumber: string) => {
  const splittedPhoneNumber = phoneNumber.match(/.{1,2}/g);
  return (splittedPhoneNumber || []).join(' ');
};

export const replaceFranceCallingCode = (value: string) =>
  splitPhoneNumber(value?.replace(/\s*/g, '').replace(/\+33\s*/g, '0'));

export const formatAutoCompleteInput = (value: string) => {
  const phoneNumberReg = /^(\+33)?[\d\s]*$/g;
  if (phoneNumberReg.test(value)) {
    return replaceFranceCallingCode(value);
  }

  return value;
};

export const boldString = (str: string, target: string) => {
  if (!target) return str;
  const re = new RegExp(target, 'g');
  return (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: str.replace(re, `<b>${target}</b>`),
      }}
    />
  );
};

export const startTlc = (
  dispatch: Function,
  videoSession: VideoSessionAggregate,
  isTlcCenterOrAmcAndHasPractitionerId: boolean,
  practitionerId: string | null,
  tlcReminder?: TeleconsultationReminder,
  // isReadyVisio: boolean = false,
) => {
  // TODO: USE NEXTACTION FOR GETONE // maybe not need after the update
  dispatch(
    serialActions.serial([
      () =>
        tlcReminder
          ? teleconsultationRemindersActions.updateOne({
              ...tlcReminder,
              isPopup: false,
            })
          : serialActions.continue(),
      () =>
        videoSessionsActions.updateOne({
          ...videoSession,
          ...(isTlcCenterOrAmcAndHasPractitionerId && { practitionerId }),
          videoSessionStatus: STARTED,
          practitionerHasHungUp: false,
          // videoSessionStatus: isReadyVisio ? STARTED : WAITING,
        }),
      () =>
        Router.push({
          pathname: Routes.TLC,
          query: {
            ...Router.query,
            centerId: videoSession.centerId,
            patientId: videoSession.patient?.id,
            practitionerId,
            videoSessionId: videoSession.id,
          },
        }),
      () =>
        videoSessionsActions.getOne(videoSession.id, {
          params: {
            aggregateWith: 'patient,center,practitioner,teleconsultationRelay',
          },
        }),
      state =>
        state.videoSessions.item?.appointmentId &&
        appointmentsTlcActions.getOne(state.videoSessions.item?.appointmentId),
    ]),
  );
};

export const getTlcCenterOrAmcAndHasCenterId = (state, centerId) => {
  const isTlcCenter = !!state.centers.item?.proFunctionalities?.TLC_CENTER;
  const isTlcAmc = !!state.centers.item?.proFunctionalities?.TLC_AMC;
  const isTlcCenterOrAmc = isTlcCenter || isTlcAmc;
  const hasCenterId = !!centerId;
  return isTlcCenterOrAmc && hasCenterId;
};

export const preventAndStop = (
  e: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e.preventDefault();
  e.stopPropagation();
};

// export const getAutoCompletePatientLabel = item => {
//   if (!item) return null;
//   const {
//     firstName = '',
//     lastName = '',
//     birthName = '',
//     birthDate = '',
//     mobilePhoneNumber = '',
//     phoneNumber = '',
//   } = item;
//   const name = `${firstName} ${lastName}`;
//   if (!birthName && !birthDate && !mobilePhoneNumber && !phoneNumber)
//     return name;

//   const birthInfo = `(${birthName ? `${birthName} ` : ''}${
//     birthDate ? moment(birthDate).format('DD/MM/YYYY') : ''
//   }) ${mobilePhoneNumber || phoneNumber}`;

//   return `${name} ${birthInfo}`.trim();
// };

// export const getPractitionerSpecialityAndCenter = item =>
//   `${get(item, 'speciality.name', 'Aucune spécialité')} - ${get(
//     item,
//     'center.name',
//     'Aucun centre',
//   )}`;

export const getPractitionerSpecialityAndCenterByAgendaSettings = (
  item: ProAgendaSettingsDTO,
) =>
  `${get(item, 'practitioner.speciality.name', 'Aucune spécialité')} - ${get(
    item,
    'center.name',
    'Aucun centre',
  )}`;

/**
 * get the closest slot start time from a given date based on settings slot duration.
 * Eg: an user want to set appointments by slices of 15 minutes, and clicks on 15:20
 * The closest slot start date is 15:15.
 */
export const getClosestSlotStartFromDateUsingDate = (
  date: Date | string,
  timeSlice: number = DEFAULT_AGENDA_APPOINTMENT_LENGTH,
) => {
  // Temporary solution to clean date of type string
  let newDate =
    typeof date === 'string' ? new Date(date.replace('hours-', '')) : date;
  const initialMinutes = newDate.getMinutes() + 1;
  const slotPerHour = 60 / timeSlice;
  if (AGENDA_APOINTMENT_ALLOWED_LENGTH.indexOf(timeSlice) === -1)
    throw new Error(
      `Unsupported setting: timeSlice should be 5, 10, 15, 20, 30, 40 or 60 but it is '${timeSlice}'`,
    );

  let i = 0;
  newDate.setMinutes(0);
  while (i < slotPerHour && newDate.getMinutes() + timeSlice < initialMinutes) {
    newDate = new Date(newDate.getTime() + timeSlice * 60000);
    i++;
  }

  return newDate;
};

/**
 * get the closest slot start time from a given date based on settings slot duration.
 * Eg: an user want to set appointments by slices of 15 minutes, and clicks on 15:20
 * The closest slot start date is 15:15.
 */
export const getClosestSlotStartFromDate = (
  date: Date | Moment,
  timeSlice: number = DEFAULT_AGENDA_APPOINTMENT_LENGTH,
) => {
  const initialMinutes = moment(date).minutes() + 1;
  const slotPerHour = 60 / timeSlice;
  if (AGENDA_APOINTMENT_ALLOWED_LENGTH.indexOf(timeSlice) === -1)
    throw new Error(
      `Unsupported setting: timeSlice should be 5, 10, 15, 20, 30, 40 or 60 but it is '${timeSlice}'`,
    );

  let i = 0;
  const newDate = moment(date);
  newDate.minutes(0);
  while (i < slotPerHour && newDate.minutes() + timeSlice < initialMinutes) {
    newDate.add(timeSlice, 'minutes');
    i++;
  }

  return newDate;
};

export const getClosestSlotEndFromDate = (
  date: Date | Moment,
  timeSlice: number = DEFAULT_AGENDA_APPOINTMENT_LENGTH,
) =>
  getClosestSlotStartFromDate(
    moment(date).add(timeSlice - 1, 'minutes'),
    timeSlice,
  );

/**
 * When getting timeslots from the backend it returns strings such as: 2019-12-13T08:00:00Z
 * However javascript's toISOString for the same dates would return :  2019-12-13T08:00:00.000Z
 * As we're doing string comparison for performance optimizations in TimeSlotWrapper,
 * we need to strings to be the exact same shape.
 * @param date
 * @returns {string}
 */
// export const toISOStringJavaPolyfill = date =>
//   `${date.getUTCFullYear()}-${padNumber(date.getUTCMonth() + 1)}-${padNumber(
//     date.getUTCDate(),
//   )}T${padNumber(date.getUTCHours())}:${padNumber(
//     date.getUTCMinutes(),
//   )}:${padNumber(date.getUTCSeconds())}Z`;

// https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
export const uuidv4 = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function toStr(c) {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export const isSentByPatient = (document: Document) =>
  (document?.sharedInformation?.sourceUserType as any)?.name === PATIENT;

export const isDocumentSharedWithLGC = (document: Document) =>
  isSentByPatient(document) &&
  document?.isToSendToSoftware &&
  document?.syncData?.some(syncData => !!syncData.externalId);

export const isLoadingSharedWithLGC = (document: Document) =>
  isSentByPatient(document) &&
  document?.isToSendToSoftware &&
  (!document?.syncData ||
    document?.syncData?.every(syncData => !syncData.externalId));

export const isPractitionerInAgendaSettings = (
  practitionerId: string | undefined,
  agendaSettings: Array<ProAgendaSettingsDTO>,
) => {
  if (!practitionerId) return false;
  return (
    agendaSettings.findIndex(
      agenda => agenda.practitionerId === practitionerId,
    ) !== -1
  );
};

export const isDocumentSharedWithAgendaSettings = (
  document: Document,
  agendaSettings: Array<ProAgendaSettingsDTO>,
) => {
  if (isPractitionerInAgendaSettings(document.practitionerId, agendaSettings))
    return true;
  const destinationSet =
    document?.sharedInformation?.practitionerDestinationSet ?? [];
  return (
    destinationSet.filter((dest: PractitionerDestination) =>
      isPractitionerInAgendaSettings(dest.practitionerId, agendaSettings),
    ).length > 0
  );
};

export const setDocumentsSeenByPrat = (documents: Document[]) => (params: {
  practitionerId: string | null;
  patientId: string | null;
}) =>
  documents
    .filter(doc => doc.patientId === params.patientId)
    .map(doc =>
      doc.practitionerId === params.practitionerId
        ? { ...doc, isSeen: true }
        : doc,
    );

export const setDocumentUrlFilter = (url: string) => {
  let urlFilter;
  const format = /[`[\]]/;
  const testString = format.test(url);
  if (testString) {
    urlFilter = url.replace(/[[\]']+/g, '');
    return urlFilter;
  }
  return url;
};

export const isAppointmentPastToday = (appointment: AppointmentGetDTO) =>
  moment(moment().startOf('day')).isAfter(
    moment(appointment.startDate).startOf('day'),
  );

export const hasAppointmentTimePassed = (appointment: AppointmentGetDTO) =>
  moment().isAfter(appointment.endDate);

export const patientInputToObject = (inputValue: string) => {
  // Extract alphabetic words (internal hyphens are allowed).
  const inputValueSplitted = (inputValue ?? '')
    .trim()
    .replace(/\s\s+/g, ' ')
    .split(' ')
    .filter(value => /^\p{Letter}(?:-?\p{Letter})*$/u.test(value));
  const firstName =
    inputValueSplitted.length > 1
      ? capitalize(inputValueSplitted.pop() as string)
      : undefined;
  const lastName = inputValueSplitted.join(' ').toUpperCase() || undefined;
  return { firstName, lastName };
};

export const substituteInputToObject = (inputValue: string) => {
  const inputValueSplitted = (inputValue ?? '')
    .trim()
    .replace(/\s\s+/g, ' ')
    .split(' ');
  const lastName =
    inputValueSplitted.length > 0
      ? (inputValueSplitted.splice(0, 1)[0] || '').toUpperCase()
      : '';
  const firstName =
    inputValueSplitted.length > 0 ? inputValueSplitted.join(' ') : '';
  return { userProInformation: { firstName, lastName } };
};

// eslint-disable-next-line no-useless-escape
export const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const noAccentsRegExp = /([à-ü]|[À-Ü])/g;

const msSanteDomains = ['mssante'];
export const excludeMsSanteDomain = (value: string = '') => {
  const regex = new RegExp(emailRegExp);
  const domain = last(regex.exec(value || ''));
  return !value || (!!domain && !msSanteDomains.includes(domain.slice(0, -1)));
};

export const copytoClipboard = (valueToBeCopied: string | number) => {
  navigator.clipboard.writeText(valueToBeCopied as string);
};
